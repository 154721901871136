/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from 'theme-ui';
import merge from "lodash.merge";
import React, { useCallback } from "react";
import { NavLink } from "react-router-dom";
import { TModeColor, useColorMode } from 'theme';

interface NavItemProps {
  to: string;
  label: string;
  icon?: React.ReactNode;
}

const activeHowerFocusKey = "a:hover, a:active, a:focus, a.nav-item__link--active";
const activeKey = "a.--active";

const styles = {
  display: "inline-block",

  a: {
    position: "relative",
    display: "flex",
    height: "44px",
    alignItems: "center",
    px: ["12px", "16px"],
    textDecoration: "none",
    fontWeight: "bolder",
    fontSize: "14px",
    margin: "-2px 0",

    "&:after": {
      content: "''",
      display: "block",
      height: "3px",
      width: "100%",
      position: "absolute",
      bottom: 0,
      left: 0,
      right: 0,
      transform: "scaleX(0)",
      transition: "200ms transform",
    },
  },

  [activeKey]: {
    borderBottomColor: "yellow",

    "&:after": {
      backgroundColor: "yellow",
      transform: "scaleX(1)",
    },
  },

  "[data-icon] + [data-label]": {
    marginLeft: "6px",
  }
}

const colorModeStyles: Record<TModeColor, {}> = {
  default: {
    a: {
      color: "n50",
    },
    [activeHowerFocusKey]: {
      color: "n90",
    },
    [activeKey]: {
      color: "n90",
    }
  },
  dark: {
    a: {
      color: "n20"
    },
    [activeHowerFocusKey]: {
      color: "n0"
    },
    [activeKey]: {
      color: "n0",
    }
  }
};

const useStyles = (props: NavItemProps) => {
  const [colorMode] = useColorMode();

  return useCallback(
    () => {
      return merge(
        styles,
        colorModeStyles[colorMode],
      );
    },
    [colorMode]
  );
};

const isActive = ({ isActive }: { isActive: boolean }): string => (isActive ? "--active" : "");

export const NavItem: React.FC<NavItemProps> = (props) => {
  const { to, label, icon } = props;
  const sx = useStyles(props);

  return (
    <li sx={sx}>
      <NavLink to={to} className={isActive}>
        {icon}
        <span data-label>{label}</span>
      </NavLink>
    </li>
  );
};
