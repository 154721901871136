/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, Flex } from "theme-ui";
import React from "react";

export interface FormGroupProps {}

export const FormGroup: React.FC<FormGroupProps> = ({ children, ...rest }) => {
  return <Flex sx={{ flexDirection: "column", gap: "xxs" }} {...rest}>{children}</Flex>
}
