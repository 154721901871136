import { setItem, getItem } from "utils";

import exercisesPL from "./pl/exercises.translations.json";
import exercisesEN from "./en/exercises.translations.json";

// TODO consider adding i18next-http-backend
export const resources = {
  pl: {
    "exercises": exercisesPL,
  },
  en: {
    "exercises": exercisesEN,
  }
} as const

export type TLocaleResources = typeof resources;
export type TLang = keyof TLocaleResources;
export type TLangNameSpace = keyof TLocaleResources["pl"];

export const DEFAULT_LANG = "en";
const LANGUAGES = ["en", "pl"];

const LANG_STORAGE_KEY = "lang";

export const localeType = (item?: any) => {
  if (item && ["en", "pl"].indexOf(item) !== -1) {
    return item;
  }

  for (const lang of navigator.languages.map(l => l.toLowerCase())) {
    for (const availableLang of LANGUAGES) {
      const regExp = new RegExp(`^${availableLang}\\b`);
      if (regExp.test(lang)) {
        return availableLang;
      }
    }
  }

  return undefined;
};

export const getStoredLang = (): TLang | undefined => {
  const item = getItem<TLang>(LANG_STORAGE_KEY);
  return localeType(item);
};
export const setStoredLang = (v: TLang) => setItem(LANG_STORAGE_KEY, v);
export const getInitLang = () => getStoredLang() || DEFAULT_LANG;
