/** @jsxRuntime classic */
/** @jsx jsx */
import { Flex, jsx } from "theme-ui";
import React, { useState } from "react";
import { IBoardSettings } from "./types";
import { useTranslation } from "react-i18next";

import { DialogDisclosure, DialogStateReturn } from "reakit/Dialog";
import { Button } from "components/Button";
import { GearIcon, RefreshIcon } from "components/Icons";

import { BoardSettingsModal } from "./ModalSettings/BoardSettingsModal";

interface Props {
  dialogState: DialogStateReturn;

  settings: IBoardSettings;
  defaultSettings: IBoardSettings;
  onSettingsChange: (settings: IBoardSettings) => void;
  onRefreshClick: () => void;
}

export const Bar: React.FC<Props> = ({
  dialogState,
  settings,
  defaultSettings,
  onSettingsChange,
  onRefreshClick,
}) => {
  const [activeTabIndex, setActiveTabIndex] = useState(0);

  const { t } = useTranslation("exercises");

  return (
    <React.Fragment>
      <Flex sx={{ gap: "xs", justifyContent: "center" }}>
        <Button
          data-action-button
          size="sm"
          variant="outline"
          iconLeft={<RefreshIcon style={{ fontSize: "18px" }}/>}
          onClick={onRefreshClick}
        />
        <DialogDisclosure
          as={Button}
          data-action-button
          variant="outline"
          size="sm"
          iconLeft={<GearIcon />}
          {...dialogState}
        >
          {t("SCHULTZ_BOARD.BUTTON_SETTINGS")}
        </DialogDisclosure>
      </Flex>

      <BoardSettingsModal
        dialogState={dialogState}
        settings={settings}
        defaultSettings={defaultSettings}
        onSettingsChange={onSettingsChange}
        activeTabIndex={activeTabIndex}
        setActiveTabIndex={setActiveTabIndex}
      />
    </React.Fragment>
  );
};
