/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from 'theme-ui';
import { useCallback } from "react";
import { NavLink } from "react-router-dom";
import merge from "lodash.merge";

import { TModeColor, useColorMode } from 'theme';

interface Props {
  to: string;
  label: string;
  icon?: React.ReactNode;
}

const hoverActicFocusKey = ":hover, :active :focus";

const styles = {
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  borderRadius: "xl",
  border: "3px solid transparent",
  padding: "sm",
  fontWeight: "bolder",
  textDecoration: "none",

  "[data-icon]": {
    height: "30px",
    fontSize: "30px",
    marginBottom: "4px",
  }
}

const colorModeStyles: Record<TModeColor, {}> = {
  default: {
    backgroundColor: "n5",
    color: "n50",
    [hoverActicFocusKey]: {
      color: "n90",
      borderColor: "yellow",
    }
  },
  dark: {
    backgroundColor: "n80",
    color: "n5",
    [hoverActicFocusKey]: {
      color: "n0",
      borderColor: "yellow",
    }
  }
};

const useStyles = (props: Props) => {
  const [colorMode] = useColorMode();

  return useCallback(
    () => {
      return merge(
        styles,
        colorModeStyles[colorMode],
      );
    },
    [colorMode]
  );
};

export const ExerciseAnchor: React.FC<Props> = (props) => {
  const {
    to,
    label,
    icon,
  } = props;

  const sx = useStyles(props);

  return (
    <NavLink to={to} sx={sx}>
      {icon}
      {label}
    </NavLink>
  );
};
