import { TModeColor } from "theme";
import { ThemeUICSSObject } from "theme-ui";

import { TColor } from "theme/colors";

export const buttonStyles: ThemeUICSSObject = {
  boxSizing: "border-box",
  appearance: "none",
  position: "relative",

  userSelect: "none",
  cursor: "pointer",

  fontFamily: "body",
  fontWeight: "bolder",
  textAlign: "center",
  textDecoration: "none",
  whiteSpace: "nowrap",
  verticalAlign: "middle",

  border: "1px solid transparent",
  borderRadius: "lg",

  transition: "200ms all",

  "&:focus": {
    zIndex: 1,
  },
};

export const contentWrapperStyles: ThemeUICSSObject = {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  height: "100%",
};

const iconWrapperStyles: ThemeUICSSObject = {
  display: "flex",
};

export const leftIconWrapperStyles: ThemeUICSSObject = {
  ...iconWrapperStyles,
};

export const rightIconWrapperStyles: ThemeUICSSObject = {
  ...iconWrapperStyles,
};

export const loadingWrapperStyles: ThemeUICSSObject = {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  position: "absolute",
  background: "inherit",
  width: "100%",
  borderRadius: "inherit",
  left: 0,
  right: 0,
  bottom: 0,
  top: 0,
};

type VariantProps = Record<string, TColor | "transparent">

const makeVariant = ({
  baseBackground,
  baseBorder,
  baseColor,
  hoverBackground,
  hoverBorder,
  hoverColor,
  activeBackground,
  activeBorder,
  activeColor,
  // disabledBackground,
  // disabledBorder,
  // disabledColor,
}: VariantProps) => {
  return {
    backgroundColor: baseBackground,
    borderColor: baseBorder,
    color: baseColor,

    // '&[aria-disabled="true"]': {
    //   cursor: "auto",
    //   backgroundColor: disabledBackground,
    //   borderColor: disabledBorder,
    //   color: disabledColor,
    // },

    '&:not([aria-disabled="true"])': {
      "&:hover": {
        backgroundColor: hoverBackground,
        borderColor: hoverBorder,
        color: hoverColor,
      },

      '&:active, &[data-active="true"]': {
        backgroundColor: activeBackground,
        borderColor: activeBorder,
        color: activeColor,
      },
    },
  };
}

type MakeVariants = Record<TModeColor, VariantProps>

const makeVariants = (props: MakeVariants) => {
  const variants: Record<TModeColor, {}> = {
    default: {},
    dark: {},
  };
  Object.keys(props).forEach(mode => {
    variants[mode as TModeColor] = makeVariant(props[mode as TModeColor]);
  });

  return variants;
};

const primaryVariant: VariantProps = {
  baseBackground: "yellow",
  baseBorder: "yellow",
  baseColor: "n90",
  hoverBackground: "yellow10",
  hoverBorder: "yellow10",
  hoverColor: "n90",
  activeBackground: "yellow10",
  activeBorder: "yellow10",
  activeColor: "n90",
}

const variants: ThemeUICSSObject = {
  primary: {
    ...makeVariants({
      default: {
        ...primaryVariant,
      },
      dark: {
        ...primaryVariant,
      }
    }),
  },
  secondary: {
    ...makeVariants({
      default: {
        baseBackground: "n10",
        baseBorder: "n10",
        baseColor: "n90",
        hoverBackground: "n20",
        hoverBorder: "n20",
        hoverColor: "n90",
        activeBackground: "n20",
        activeBorder: "n20",
        activeColor: "n90",
      },
      dark: {
        baseBackground: "n70",
        baseBorder: "n70",
        baseColor: "n0",
        hoverBackground: "n60",
        hoverBorder: "n60",
        hoverColor: "n0",
        activeBackground: "n60",
        activeBorder: "n60",
        activeColor: "n0",
      }
    }),
  },
  outline: {
    ...makeVariants({
      default: {
        baseBackground: "n0",
        baseBorder: "n10",
        baseColor: "n90",
        hoverBackground: "n10",
        hoverBorder: "n10",
        hoverColor: "n90",
        activeBackground: "n10",
        activeBorder: "n10",
        activeColor: "n90",
      },
      dark: {
        baseBackground: "n90",
        baseBorder: "n70",
        baseColor: "n0",
        hoverBackground: "n70",
        hoverBorder: "n70",
        hoverColor: "n0",
        activeBackground: "n70",
        activeBorder: "n70",
        activeColor: "n0",
      }
    }),
  },
  ghost: {
    ...makeVariants({
      default: {
        baseBackground: "transparent",
        baseBorder: "transparent",
        baseColor: "n90",
        hoverBackground: "n10",
        hoverBorder: "n10",
        hoverColor: "n90",
        activeBackground: "n10",
        activeBorder: "n10",
        activeColor: "n90",
      },
      dark: {
        baseBackground: "transparent",
        baseBorder: "transparent",
        baseColor: "n0",
        hoverBackground: "n70",
        hoverBorder: "n70",
        hoverColor: "n0",
        activeBackground: "n70",
        activeBorder: "n70",
        activeColor: "n0",
      }
    }),
  },
};

const makeSize = ({
  height,
  lineHeight,
  fontSize,
  padding,
  iconFontSize,
  iconMargin,
}: Record<string, string>) => ({
  height,
  lineHeight,
  fontSize,
  padding,

  "[data-icon], [data-loader]": {
    fontSize: iconFontSize,
  },

  "[data-left-icon-wrapper] + [data-children], [data-children] + [data-right-icon-wrapper]":
    {
      ml: iconMargin,
    },

  '&[data-icon-button="true"]': {
    width: height,
  },
});

const sizes = {
  lg: makeSize({
    height: "48px",
    lineHeight: "48px",
    fontSize: "16px",
    padding: "0 16px",
    iconFontSize: "24px",
    iconMargin: "xs",
  }),
  md: makeSize({
    height: "40px",
    lineHeight: "40px",
    fontSize: "16px",
    padding: "0 16px",
    iconFontSize: "24px",
    iconMargin: "xs",
  }),
  sm: makeSize({
    height: "32px",
    lineHeight: "32px",
    fontSize: "14px",
    padding: "0 12px",
    iconFontSize: "14px",
    iconMargin: "xxs",
  }),
};

export const button = {
  variants,
  sizes,
};
