/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from "theme-ui";
import React, { useMemo } from "react";
import merge from "lodash.merge";

import { ISettings, TMode } from "../types";

import { boardStyles } from "./styles";
import { boardFontSizes, boardLineHeight, fontTypes } from "../styles";
import { TModeColor, useColorMode } from 'theme';

interface Props {
  mode: TMode;
  settings: ISettings;
}

const colorModeStyles: Record<TModeColor, {}> = {
  default: {
    backgroundColor: "n10",
    "[data-hidden-chars]": {
      backgroundColor: "n0",
    },
  },
  dark: {
    backgroundColor: "n80",
    "[data-hidden-chars]": {
      backgroundColor: "n50",
    }
  },
};

export const BoardContainer: React.FC<Props> = (props) => {
  const { mode, settings, children } = props;
  const { fontSize, fontType, upsideDownWords, textPosition } = settings;
  const [colorMode] = useColorMode();

  const styles = useMemo(() => {
    return {
      ...merge(boardStyles, colorModeStyles[colorMode]),

      fontSize: boardFontSizes[fontSize],
      lineHeight: boardLineHeight[fontSize],
      fontFamily: fontTypes[fontType],
    };
  }, [colorMode, fontSize, fontType]);

  return (
    <div
      sx={styles}
      data-upside-down={upsideDownWords}
      data-text-position={mode === "static" ? "justified" : textPosition}
    >
      {children}
    </div>
  );
};
