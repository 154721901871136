/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from "theme-ui";
import React from "react";
import { useTranslation } from "react-i18next";

import { DialogStateReturn } from "reakit/Dialog";

import { Modal } from "components/Dialogs/Modal";

import { ISettings } from "../types";
import { SpreaderSettingsModalContent } from "./SpreaderSettingsModalContent";

interface SpreaderSettingsModalProps {
  defaultSettings: ISettings;
  settings: ISettings;
  onSettingsChange: (settigns: ISettings) => void;
  dialogState: DialogStateReturn;

  activeTabIndex: number;
  setActiveTabIndex: (val: number) => void;
}

export const SpreaderSettingsModal: React.FC<SpreaderSettingsModalProps> = ({
  defaultSettings,
  settings,
  onSettingsChange,
  dialogState,
  activeTabIndex,
  setActiveTabIndex
}) => {
  const { t } = useTranslation("exercises");

  const handleSubmitClick = (settings: ISettings) => {
    onSettingsChange(settings);
    dialogState.hide();
  };

  return (
    <Modal
      aria-label={t("SPREADER.SETTINGS.MODAL_ARIA_LABEL")}
      {...dialogState}
    >
      <SpreaderSettingsModalContent
        defaultSettings={defaultSettings}
        settings={settings}
        onCloseClick={dialogState.hide}
        onSubmitClick={handleSubmitClick}
        activeTabIndex={activeTabIndex}
        setActiveTabIndex={setActiveTabIndex}
      />
    </Modal>
  );
};
