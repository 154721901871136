/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from "theme-ui";
import React, { useCallback } from "react";
import merge from "lodash.merge";

import { useColorMode, TModeColor } from "theme";

interface Props {}

const styles = {
  borderRadius: "lg",
  p: "sm",
}

const colorModeStyles: Record<TModeColor, {}> = {
  default: {
    backgroundColor: "n5",
  },
  dark: {
    backgroundColor: "n70",
  },
};

const useStyles = (props: Props) => {
  const [colorMode] = useColorMode();

  return useCallback(() => {
    return merge(styles, colorModeStyles[colorMode]);
  }, [colorMode]);
};

export const Card: React.FC<Props> = (props) => {
  const { children, ...rest } = props;
  const styles = useStyles(props);
  return <div sx={styles} {...rest}>{children}</div>
}
