/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from "theme-ui";
import { forwardRef, Ref, MouseEvent, useCallback } from "react";
import merge from "lodash.merge";

import { useColorMode } from "theme";

import { switchStyles, colorModeStyles } from "./config";

type ISwitchHTMLProps = Omit<
  JSX.IntrinsicElements["button"],
  "onClick" | "onChange" | "role"
>;

const useStyles = (props: ISwitchHTMLProps) => {
  const [colorMode] = useColorMode();

  return useCallback(() => {
    return merge(switchStyles, colorModeStyles[colorMode]);
  }, [colorMode]);
};

interface SwitchComponentProps extends ISwitchHTMLProps {
  selected?: boolean | null;
  onChange?: (selected: boolean, event: MouseEvent<HTMLButtonElement>) => void;
  focusable?: boolean;
  readOnly?: boolean;
}

const SwitchComponent = (
  props: SwitchComponentProps,
  buttonRef: Ref<HTMLButtonElement>
) => {
  const {
    selected,
    onChange,
    disabled,
    focusable = true,
    readOnly,
    ...buttonProps
  } = props;

  const isReadonly = (disabled && focusable) || readOnly;

  const handleOnClick = useCallback(
    (event: MouseEvent<HTMLButtonElement>) => {
      if (typeof onChange === "function" && !isReadonly) {
        onChange(!selected, event);
      }
    },
    [onChange, isReadonly, selected]
  );

  const styles = useStyles(props);

  return (
    <button
      role="switch"
      type="button"
      ref={buttonRef}
      sx={styles}
      onClick={handleOnClick}
      aria-checked={selected ? "true" : "false"}
      disabled={!focusable && disabled}
      aria-disabled={Boolean(disabled)}
      {...buttonProps}
    >
      <span data-switch aria-hidden="true" />
    </button>
  );
};

export const Switch = forwardRef<HTMLButtonElement, SwitchComponentProps>(
  SwitchComponent
);
export type SwitchProps = React.ComponentPropsWithRef<typeof Switch>;
