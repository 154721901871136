export const typography = {
  fonts: {
    body: `"Montserrat", sans-serif`,
  },
  lineHeight: {
    "p-xs": "14px",
    "p-sm": "14px",
    "p-md": "22px",
    "p-lg": "24px",
    "p-xl": "28px",
  },
  fontSizes: {
    "p-xs": 10,
    "p-sm": 12,
    "p-md": 14,
    "p-lg": 16,
    "p-xl": 18,
  },
  text: {
    sizes: {
      "p-xs": {
        fontSize: "p-xs",
        lineHeight: "p-xs",
      },
      "p-sm": {
        fontSize: "p-sm",
        lineHeight: "p-sm",
      },
      "p-md": {
        fontSize: "p-md",
        lineHeight: "p-md",
      },
      "p-lg": {
        fontSize: "p-lg",
        lineHeight: "p-lg",
      },
      "p-xl": {
        fontSize: "p-xl",
        lineHeight: "p-xl",
      },
    },
  },
  fontWeights: {
    normal: 400,
    bold: 500,
    bolder: 600,
  }
};
