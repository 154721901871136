/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from 'theme-ui';
import React, { useCallback, MouseEvent } from "react";

import { TTheme, useColorMode } from "theme";
import {
  forwardRefWithAs,
  PropsWithAs,
  getResponsiveStyles,
  TResponsiveValue,
  memoize,
} from "theme/utils";

import {
  buttonStyles,
  loadingWrapperStyles,
  leftIconWrapperStyles,
  rightIconWrapperStyles,
  contentWrapperStyles,
} from "./config";

const isIconButton = (
  iconLeft: boolean,
  children: boolean,
  iconRight: boolean
): boolean => {
  return !children && ((iconLeft && !iconRight) || (!iconLeft && iconRight));
};

export type ButtonVariant = keyof TTheme["button"]["variants"];
export type ButtonSize = keyof TTheme["button"]["sizes"];
export type ButtonDisplay = "inline-block" | "block";

export interface ButtonComponentProps {
  /**
   * Sets a button style
   * @default primary
   */
  variant?: ButtonVariant;

  /**
   * Sets a button size
   * @default md
   */
  size?: ButtonSize | TResponsiveValue<ButtonSize>;

  /**
   * Changes the button display mode
   * @default inline-block
   */
  display?: ButtonDisplay;

  /**
   * Determines if the button is active or not
   * @default false
   */
  active?: boolean;

  /**
   * Determines if the button is disabled or not
   * @default false
   */
  disabled?: boolean;

  /**
   * Determines if the button can be focused
   * @default false
   */
  focusable?: boolean;

  /**
   * Sets the loading state of the button
   * @default false
   */
  loading?: boolean;

  /**
   * Shows the icon on the left side of the button
   * @default undefined
   */
  iconLeft?: React.ReactNode;

  /**
   * Shows the icon on the right side of the button
   * @default undefined
   */
  iconRight?: React.ReactNode;
}

const getKey = (
  _theme: TTheme["button"]["sizes"],
  size: ButtonSize | TResponsiveValue<ButtonSize>
) => {
  return Array.isArray(size) ? size.join("-") : size;
};

const getSize = memoize(getResponsiveStyles, getKey);

const useButtonStyles = (props: ButtonComponentProps) => {
  const { size = "md", variant = "primary", display = "inline-block" } = props;
  const [colorMode] = useColorMode();

  return useCallback(
    (theme: TTheme) => {
      const displayStyles =
        display === "block"
          ? { display: "block", width: "100%" }
          : { display: "inline-block" };

      return {
        ...buttonStyles,
        ...displayStyles,
        ...getSize(theme.button.sizes, size),
        variant: `button.variants.${variant}.${colorMode}`,
      };
    },
    [display, size, variant, colorMode]
  );
};

export const ButtonComponent = (
  props: PropsWithAs<ButtonComponentProps, "button">,
  forwardedRef: React.Ref<HTMLButtonElement>
) => {
  const {
    as: Component = "button",
    active,
    children,
    size,
    variant,
    display,
    disabled,
    focusable = true,
    loading,
    iconLeft,
    iconRight,
    onClick,
    ...rest
  } = props;

  const sxFun = useButtonStyles(props);

  const handleOnClick = useCallback(
    (event: MouseEvent<HTMLButtonElement>) => {
      if (!disabled) {
        onClick?.(event);
      }
    },
    [onClick, disabled]
  );

  return (
    <Component
      sx={sxFun}
      ref={forwardedRef}
      aria-disabled={Boolean(disabled)}
      disabled={!focusable && disabled}
      data-icon-button={isIconButton(
        Boolean(iconLeft),
        Boolean(children),
        Boolean(iconRight)
      )}
      data-active={!!active}
      data-button
      onClick={handleOnClick}
      {...rest}
    >
      <span data-content-wrapper sx={contentWrapperStyles}>
        {iconLeft && (
          <span data-left-icon-wrapper sx={leftIconWrapperStyles}>
            {iconLeft}
          </span>
        )}
        {children && <span data-children>{children}</span>}
        {iconRight && (
          <span data-right-icon-wrapper sx={rightIconWrapperStyles}>
            {iconRight}
          </span>
        )}
      </span>
      {loading && (
        <span sx={loadingWrapperStyles}>
          {'<Loader />'}
        </span>
      )}
    </Component>
  );
};

export type ButtonProps = PropsWithAs<ButtonComponentProps, "button" | "a">;
export const Button = forwardRefWithAs<ButtonComponentProps, "button" | "a">(
  ButtonComponent
);
