import { useMemo } from "react";

import { columnWidthPercentMin, columnWidthPercentMax } from "../const";
import { TMode } from '../types';

interface Props {
  columnWidthPercent?: number;
  mode: TMode;
}

export const useInnerBoardContainerStyles = ({ columnWidthPercent, mode }: Props) => {
  return useMemo(() => {
    if (mode === "dynamic") {
      return {
        flex: 1,
      }
    }

    if (typeof columnWidthPercent !== "number") {
      return {
        flex: 1,
      }
    }

    return {
      flex:
      columnWidthPercent >= columnWidthPercentMin &&
      columnWidthPercent <= columnWidthPercentMax
        ? columnWidthPercent / 100
        : 1,
    };
  }, [columnWidthPercent, mode]);
}
