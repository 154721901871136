/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from "theme-ui";
import React, { PropsWithChildren } from "react";
import { Dialog as ReakitDialog } from "reakit/Dialog";

import { TTheme } from "theme";
import { forwardRefWithAs, PropsWithAs } from "theme/utils";

import { DialogBackdrop } from "../DialogBackdrop";
import { DialogHeader, DialogHeaderProps } from "../DialogHeader";
import { DialogContent, DialogContentProps } from "../DialogContent";
import { DialogFooter, DialogFooterProps } from "../DialogFooter";
import { DialogContainer } from "../DialogContainer";

export interface ModalHeaderProps extends DialogHeaderProps {}
export const ModalHeader = DialogHeader;

export interface ModalContentProps extends DialogContentProps {}

export const ModalContent = React.forwardRef<
  HTMLDivElement,
  PropsWithChildren<ModalContentProps>
>((props, ref) => {
  const { children, ...rest } = props;

  return (
    <DialogContent {...rest} ref={ref}>
      {children}
    </DialogContent>
  );
});

export interface ModalFooterProps extends DialogFooterProps {}

export const ModalFooter = React.forwardRef<
  HTMLDivElement,
  PropsWithChildren<ModalFooterProps>
>((props, ref) => (
  <DialogFooter sx={{ justifyContent: "space-between" }} {...props} ref={ref}>
    {props.children}
  </DialogFooter>
));

interface ModalComponentProps
  extends React.ComponentPropsWithoutRef<typeof ReakitDialog> {}

const ModalComponent = (
  props: PropsWithAs<ModalComponentProps>,
  ref: React.Ref<HTMLDivElement>
) => {
  const {
    children,
    hideOnClickOutside,
    "aria-label": ariaLabel,
    "aria-labelledby": ariaLabelLedBy,
    hideOnEsc,
    as = "div",
    ...rest
  } = props;

  return (
    <DialogBackdrop {...rest}>
      <ReakitDialog
        hideOnClickOutside={rest.animating ? false : hideOnClickOutside}
        hideOnEsc={hideOnEsc}
        aria-label={ariaLabel}
        aria-labelledby={ariaLabelLedBy}
        {...rest}
        ref={ref}
        sx={{
          maxWidth: [null, null, "600px"],
          marginX: "auto",
          height: ["100%", "100%", "auto"]
        }}
      >
        {rest.visible && (

            <DialogContainer
              as={as}
              sx={(theme: TTheme) => ({
                minHeight: "100%",
                width: "100%",
                marginY: [null, null, "lg"], // goes together with  above calcutation
                borderRadius: [0, 0, "lg"],
              })}
            >
              {children}
            </DialogContainer>
        )}
      </ReakitDialog>
    </DialogBackdrop>
  );
};

export type ModalProps = PropsWithAs<ModalComponentProps, "div">;
export const Modal = forwardRefWithAs<ModalComponentProps, "div">(
  ModalComponent
);
