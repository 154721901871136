import { ThemeUICSSObject } from "theme-ui";

import { TModeColor } from "theme";

export const switchStyles: ThemeUICSSObject = {
  background: "none",
  border: "none",
  display: "inline-block",
  position: "relative",
  width: "54px",
  height: "32px",
  borderRadius: "16px",
  outline: "none",

  "&:active": {
    color: "inherit",
  },

  "[data-switch]": {
    // ::before - background, ::after - handle
    "&::before, &::after": {
      content: '""',
      position: "absolute",
      top: "50%",
      transform: "translateY(-50%)",
      cursor: "pointer",
    },
    // Default - Off
    "&::before": {
      borderWidth: 1,
      borderStyle: "solid",
      boxSizing: "border-box",

      right: 0,
      transition: "all .2s ease-in-out",
      width: 54,
      height: 32,
      borderRadius: 16,
    },
    "&::after": {
      borderRadius: "50%",
      height: 28,
      width: 28,
      right: 24,
      transition: "all .1825s ease-in-out",
    },
  },

  "&[aria-checked=true]": {
    "[data-switch]": {
      // Default - On
      "&::before": {
        backgroundColor: "yellow",
        borderColor: "yellow",
      },
      "&::after": {
        right: "2px",
        backgroundColor: "n0",
      },
    },
  },

  // "&[aria-disabled=true]": {
  //   "[data-switch]": {
  //     "&::before, &::after": {
  //       cursor: "default",
  //     },
  //     // Disabled - Off
  //     "&::before": {
  //       backgroundColor: "n50",
  //       borderColor: "n100",
  //     },
  //     "&::after": {
  //       backgroundColor: "n300",
  //     },
  //   },
  // },


  // "&[aria-disabled=true][aria-checked=true]": {
  //   "[data-switch]": {
  //     // Disabled - On
  //     "&::before": {
  //       backgroundColor: "n300",
  //     },
  //   },
  // },
};


export const colorModeStyles: Record<TModeColor, {}> = {
  default: {
    "[data-switch]": {
      "&::before": {
        borderColor: "n10",
        backgroundColor: "n10",
      },
      "&::after": {
        backgroundColor: "n0",
      },
    },
  },
  dark: {
    "[data-switch]": {
      "&::before": {
        borderColor: "n60",
        backgroundColor: "n60",
      },
      "&::after": {
        backgroundColor: "n0",
      },
    },
  },
};
