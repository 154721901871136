import { memoize } from "lodash-es";

import { matchSpecialChar } from "./matchSpecialChar";

export const splitWord = memoize((word: string) => {
  let prefix = '';
  let text = '';
  let suffix = '';

  if (!word.trim().length) {
    return [prefix, text, suffix];
  }

  let lastPrefixIndex = null;
  let hasText = false;

  for (let i = 0; i < word.length; i++) {
    const letter = word[i];

    if (matchSpecialChar(letter)) {
      prefix = prefix + letter;
      lastPrefixIndex = i;
    } else {
      break;
    }
  }

  if (lastPrefixIndex === word.length - 1) { // only special characters
    return [prefix, text, suffix];
  }

  lastPrefixIndex = lastPrefixIndex || 0;

  for (let i = word.length - 1; i > lastPrefixIndex; i--) {
    const letter = word[i];

    if (matchSpecialChar(letter) && !hasText) {
      suffix = letter + suffix;
    } else {
      text = letter + text;
      hasText = true;
    }
  }

  if (typeof lastPrefixIndex === 'number' && !matchSpecialChar(word[lastPrefixIndex])) {
    text = word[lastPrefixIndex] + text;
  }

  return [prefix, text, suffix];
}, (word: string) => word);
