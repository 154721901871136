export type TResponsiveValue<V = string | number> = V | Array<V | null>;

export const getResponsiveValue = (
  object: Record<string | number, any>,
  key: TResponsiveValue,
  index: number
) => {
  if (Array.isArray(key)) {
    if (index > key.length - 1) {
      const d1 = key[key.length - 1];

      if (d1) {
        return object[d1];
      }
    }

    const d2 = key[index];

    if (d2) {
      return object[d2];
    }

    return undefined;
  }

  if (!key) {
    return undefined;
  }

  return object[key];
};

type TInput = TResponsiveValue;
type TTheme = { [key: string]: any };
type TStyles = { [key: string]: Array<any> };

export const getResponsiveStyles = (theme: TTheme, input: TInput): TStyles => {
  if (!Array.isArray(input)) {
    return theme[input];
  }

  // find all css properties
  const themeKeys = input
    .map((key) => (key ? theme[key] : key))
    .map((obj) => (obj && typeof obj === "object" ? Object.keys(obj) : obj))
    .filter(Boolean)
    .filter((a) => a.length)
    .reduce((curr, next) => [...curr, ...next], []);

  // have them unique
  const uniqueKeys = new Set(themeKeys);

  // init empty arrays for each property
  const styles: TStyles = Array.from(uniqueKeys.values()).reduce(
    (curr: Record<string, unknown>, next: any) => {
      return { ...curr, [next]: [] };
    },
    {}
  );

  // put values (if defined) into array based on input sizes
  for (const index in input) {
    const key = input[index];

    Object.keys(styles).forEach((style) => {
      if (key !== null && theme[key][style] !== undefined) {
        styles[style][index] = theme[key][style];
        return;
      }

      styles[style][index] = null;
    });
  }

  return styles;
};

type FValue = (theme: any, input: any) => any;
type FKey = (theme: any, input: any) => string;

export const memoize = (getValue: FValue, getKey: FKey) => {
  const map = new Map<string, any>();

  return (theme: any, input: any) => {
    const key = getKey(theme, input);

    const styles = map.get(key);

    if (styles) {
      return styles;
    }

    const resStyles = getValue(theme, input);
    map.set(key, resStyles);
    return resStyles;
  };
};
