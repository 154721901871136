import React from "react";

export type As<Props = any> = React.ElementType<Props>;

export type PropsWithAs<Props = Record<string, unknown>, Type extends As = As> = Props &
  Omit<React.ComponentProps<Type>, "as" | keyof Props> & {
    as?: Type;
  };

export interface ComponentWithAs<Props, DefaultType extends As> {
  <Type extends As>(props: PropsWithAs<Props, Type> & { as: Type }): JSX.Element;
  (props: PropsWithAs<Props, DefaultType>): JSX.Element;
}

/**
 * @example
 * type ButtonProps = {
 *   isDisabled?: boolean;
 * };
 *
 * function ButtonComponent(
 *   props: PropsWithAs<ButtonProps, "button">,
 *   ref: React.Ref<HTMLButtonElement>
 * ) {
 *   const { as: Type = "button", isDisabled, ...rest } = props;
 *   return <Type disabled={isDisabled} ref={ref} {...rest} />;
 * }
 *
 * const Button = forwardRefWithAs<ButtonProps, "button">(ButtonComponent);
 *
 * @param component forwardRef ready component (expecting props and ref)
 */
export function forwardRefWithAs<Props, DefaultType extends As>(
  component: React.ForwardRefRenderFunction<any, any>,
) {
  return (React.forwardRef(component) as unknown) as ComponentWithAs<Props, DefaultType>;
}
