interface Viewport {
  breakpoint: string;
}

export const viewports: readonly Viewport[] = [
  {
    breakpoint: "576px",
  },
  {
    breakpoint: "900px",
  },
  {
    breakpoint: "1200px",
  },
];

export const breakpoints: string[] = viewports.map(
  (viewport) => viewport.breakpoint
);
