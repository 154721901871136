/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, Flex } from "theme-ui";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { DialogDisclosure, DialogStateReturn } from "reakit/Dialog";

import { useColorMode } from "theme";

import { Button } from "components/Button";
import { Status } from "components/Status";
import { Slider } from "components/Slider";
import { GearIcon, PreviousIcon, PauseIcon, PlayIcon } from "components/Icons";

import { SpreaderSettingsModal } from "./ModalSettings/SpreaderSettingsModal";
import { ISettings, TActionState } from "./types";

interface SliderWrapperProps {
  wordsPerMinute: number;
  progress: number | number[];
  progressMax: number;
  onProgressChange: (val: number | number[]) => void;
  onProgressBeforeChange?: () => void;
  onProgressAfterChange?: () => void;
}

const SliderWrapper: React.FC<SliderWrapperProps> = ({
  wordsPerMinute,
  progress,
  progressMax,
  onProgressChange,
  onProgressBeforeChange,
  onProgressAfterChange,
}) => {
  const [colorMode] = useColorMode();

  return (
    <Flex
      sx={{
        flexGrow: 1,
        border: "1px solid",
        borderColor: colorMode === "default" ? "n10" : "n70",
        borderRadius: "lg",
        alignItems: "center",
        px: "sm",
      }}
    >
      <div sx={{ flexGrow: 1 }}>
        <Slider
          value={progress}
          onChange={onProgressChange}
          onBeforeChange={onProgressBeforeChange}
          onAfterChange={onProgressAfterChange}
          step={1}
          min={0}
          max={progressMax}
        />
      </div>
      <Status size="sm" sx={{ ml: "sm" }}>
        {wordsPerMinute} wpm
      </Status>
    </Flex>
  );
};

interface SpreaderBarProps {
  dialogState: DialogStateReturn;

  hideActionBar: boolean;
  progress: number | number[];
  progressMax: number;
  onProgressChange: (val: number | number[]) => void;
  onProgressBeforeChange?: () => void;
  onProgressAfterChange?: () => void;

  defaultSettings: ISettings;
  settings: ISettings;
  onSettingsChange: (settings: ISettings) => void;

  onPrevClick: () => void;
  onPauseClick: () => void;
  onPlayClick: () => void;

  actionState: TActionState;
  wordsPerMinute: number;
}

export const SpreaderBar: React.FC<SpreaderBarProps> = ({
  dialogState,
  hideActionBar,
  onProgressChange,
  onPrevClick,
  onPauseClick,
  onPlayClick,
  progress,
  progressMax,
  actionState,
  settings,
  defaultSettings,
  onSettingsChange,
  wordsPerMinute,
  onProgressBeforeChange,
  onProgressAfterChange,
}) => {
  const { t } = useTranslation("exercises");

  const [activeTabIndex, setActiveTabIndex] = useState(0);

  return (
    <React.Fragment>
      <Flex
        sx={{
          gap: "xs",
          flexWrap: ["wrap", null],
          justifyContent: "flex-end",
          py: "sm",
          position: "sticky",
          bottom: 0,
          bg: "bodyBg",
        }}
      >
        {!hideActionBar && (
          <Flex sx={{ gap: "xs", flex: ["0 0 100%", "auto"] }}>
            <Button
              data-action-button
              size="sm"
              variant="outline"
              iconLeft={<PreviousIcon />}
              onClick={onPrevClick}
            />
            <Button
              data-action-button
              size="sm"
              variant="outline"
              iconLeft={actionState === "PLAY" ? <PauseIcon /> : <PlayIcon />}
              onClick={() =>
                actionState === "PLAY" ? onPauseClick() : onPlayClick()
              }
            />
            <SliderWrapper
              wordsPerMinute={wordsPerMinute}
              progressMax={progressMax}
              progress={progress}
              onProgressChange={onProgressChange}
              onProgressBeforeChange={onProgressBeforeChange}
              onProgressAfterChange={onProgressAfterChange}
            />
          </Flex>
        )}
        <DialogDisclosure
          sx={{ flexGrow: [1, 0] }}
          as={Button}
          data-action-button
          variant="outline"
          size="sm"
          iconLeft={<GearIcon />}
          {...dialogState}
        >
          {t("SPREADER.BUTTON_SETTINGS")}
        </DialogDisclosure>
      </Flex>

      <SpreaderSettingsModal
        dialogState={dialogState}
        settings={settings}
        defaultSettings={defaultSettings}
        onSettingsChange={onSettingsChange}
        activeTabIndex={activeTabIndex}
        setActiveTabIndex={setActiveTabIndex}
      />
    </React.Fragment>
  );
};
