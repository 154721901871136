import React, { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";

import { setStoredLang, TLang } from "locales";

interface IChangeLangOpts {
  store: boolean;
}

interface ILangContext {
  lang: TLang;
  changeLang: (value: TLang, opts?: IChangeLangOpts) => void;
}

export const LangContext = React.createContext<ILangContext>({} as ILangContext);

export const LangProvider: React.FunctionComponent = ({ children }) => {
  const { i18n } = useTranslation();

  const changeLang = useCallback((v: TLang, opts = { store: true }) => {
    setStoredLang(v);
    i18n.changeLanguage(v);
  }, [i18n]);

  const contextValue = useMemo(() => {
    return {
      lang: i18n.language as TLang,
      changeLang,
    };
  }, [changeLang, i18n.language]);

  return <LangContext.Provider value={contextValue}>{children}</LangContext.Provider>;
};
