import { css, Global } from "@emotion/core";
import { FC } from "react";

import { reset } from "./reset";
import { fonts } from "./fonts";

export const GlobalStyles: FC = () => {
  return (
    <Global
      styles={(_) => ({
        ...css(fonts + reset),
      })}
    />
  );
};
