/** @jsxRuntime classic */
/** @jsx jsx */
import React, { useCallback, useRef } from "react";
import { jsx } from "theme-ui";
import { TTheme, useColorMode } from "theme";
import merge from "lodash.merge";

import { cleanEditableContent } from "utils/text";

import { styles, colorModeStyles } from "./config";

export type HTMLContentEditableProps = Omit<
  JSX.IntrinsicElements["div"],
  "onChange" | "ref" | "value"
>;

const useStyles = (props: HTMLContentEditableProps) => {
  const [colorMode] = useColorMode();

  return useCallback(
    (theme: TTheme) => {
      return merge(styles(theme), colorModeStyles(theme)[colorMode]);
    },
    [colorMode]
  );
};

export interface ContentEditableProps extends HTMLContentEditableProps {
  html?: string;
  focusable?: boolean;
  invalid?: boolean;
  disabled?: boolean;
  readOnly?: boolean;
  onChange?: (value: string) => void;
}

export const ContentEditable = React.forwardRef<
  HTMLDivElement,
  ContentEditableProps
>((props, forwardedRef) => {
  const {
    disabled,
    focusable = true,
    readOnly,
    html,
    onChange,
    invalid,
    ...inputProps
  } = props;
  const innerRef = useRef<any>();
  const isReadOnly = (disabled && focusable) || readOnly;

  const handleChange = useCallback(
    (e) => {
      let html = cleanEditableContent(innerRef.current?.innerHTML);

      if (onChange && !isReadOnly) {
        onChange(html);
      }
    },
    [onChange, isReadOnly]
  );

  const isDisabled = !focusable && disabled;

  const sx = useStyles(props);

  return (
    <div
      sx={sx}
      aria-disabled={Boolean(disabled)}
      contentEditable={!isDisabled}
      onBlur={handleChange}
      data-invalid={Boolean(invalid)}
      {...inputProps}
      ref={innerRef}
      dangerouslySetInnerHTML={{ __html: `${html}` }}
    />
  );
});
