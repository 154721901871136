/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from "theme-ui";
import { FC, useCallback } from "react";
import RcSlider, { SliderProps as RCSliderProps } from "rc-slider";
import { useColorMode } from 'theme';
import merge from "lodash.merge";

import { styles, colorModeStyles } from "./config";

type RCSliderGenericProps = Pick<
  RCSliderProps,
  | "value"
  | "defaultValue"
  | "min"
  | "max"
  | "step"
  | "onChange"
  | "onBeforeChange"
  | "onAfterChange"
  | "disabled"
  | "tabIndex"
  | "ariaLabelForHandle"
  | "ariaLabelledByForHandle"
  | "ariaValueTextFormatterForHandle"
  | "startPoint"
  | "autoFocus"
  | "onFocus"
  | "onBlur"
  | "className"
  | "dots"
  | "draggableTrack"
>;

export interface SliderProps extends RCSliderGenericProps {}

const useStyles = (props: SliderProps) => {
  const [colorMode] = useColorMode();

  return useCallback(
    () => {
      return merge(
        styles,
        colorModeStyles[colorMode],
      );
    },
    [colorMode]
  );
};

export const Slider: FC<SliderProps> = (props) => {
  const styles = useStyles(props);
  return <RcSlider sx={styles} {...props} />;
};
