import {
  PageContainer,
  PageContent,
} from "components/Page";

import { Text } from "components/Typography";

export const ContactPage = () => {
  return (
    <PageContainer>
      <PageContent>
        <Text size="p-md" sx={{ fontWeight: "bolder" }} as="div">Barbara Antonowicz</Text>
        <Text size="p-md" as="div">info@sensus.edu.pl</Text>
        <Text size="p-md" as="div">503 592 125</Text>
      </PageContent>
    </PageContainer>
  );
};
