/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from "theme-ui";
import React from "react";
import { useColorMode } from 'theme';

import { forwardRefWithAs, PropsWithAs } from "theme/utils";

interface DialogContainerComponentProps {}

const DialogContainerComponent = (
  props: PropsWithAs<DialogContainerComponentProps>,
  ref: React.Ref<HTMLDivElement>
) => {
  const { children, ...rest } = props;
  const [colorMode] = useColorMode();

  return (
    <div
      sx={{
        display: "flex",
        flexDirection: "column",
        backgroundColor: colorMode === "default" ? "n0" : "n90",
      }}
      {...rest}
      ref={ref}
      data-component="dialog-container"
    >
      {children}
    </div>
  );
};

export type DialogContainerProps = PropsWithAs<
  DialogContainerComponentProps,
  "div"
>;
export const DialogContainer = forwardRefWithAs<
  DialogContainerComponentProps,
  "div"
>(DialogContainerComponent);
