import i18next from "i18next";
import { initReactI18next } from "react-i18next";

import { TLangNameSpace, resources, getInitLang } from "./resources";

const defaultNamespace: TLangNameSpace = "exercises";

export const i18nInstance = i18next.createInstance();

i18nInstance.use(initReactI18next).init({
  fallbackLng: getInitLang(),

  keySeparator: ".",
  resources,

  interpolation: {
    prefix: "{",
    suffix: "}",
    escapeValue: false,
  },
  ns: defaultNamespace,
  defaultNS: defaultNamespace,
});
