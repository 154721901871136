/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, Flex } from "theme-ui";
import React from "react";
import { useTranslation } from "react-i18next";

import { Switch } from "components/Switch";
import { NumberInput } from "components/NumberInput";
import { FormLabel, FormGroup } from "components/Form";
import { Slider, SliderRangeLabel } from "components/Slider";
import { Card } from "components/Card";
import { RadioButton, RadioButtonGroup } from "components/RadioButton";

import { ISettings } from "../types";
import {
  wordsPerMinuteMax,
  wordsPerMinuteMin,
  wordsPerMinuteStep,
  columnWidthPercentMax,
  columnWidthPercentMin,
  columnWidthPercentStep,
  linearPointerWidthPercentMax,
  linearPointerWidthPercentMin,
  linearPointerWidthPercentStep,
} from "../const";

interface StaticOnlySettingsProps {
  settings: ISettings;
  onChange: (settings: ISettings) => void;
}

export const StaticOnlySettings: React.FC<StaticOnlySettingsProps> = ({
  settings,
  onChange,
}) => {
  const { t } = useTranslation("exercises");

  const {
    wordsPerMinute,
    showPointer,
    columnWidthPercent,
    linearPointerWidthPercent,
    pointerType,
  } = settings;
  const wordsSliderValue = Number(wordsPerMinute);
  const columnWidthPercentSliderValue = Number(columnWidthPercent);
  const linearPointerWidthPercentSliderValue = Number(linearPointerWidthPercent);

  const punctualPointsAmount = String(settings.punctualPointsAmount);

  const handleSettingChange = (name: string) => (value: any) => {
    const v = name === "punctualPointsAmount" ? Number(value) : value;
    onChange({ ...settings, [name]: v });
  };

  return (
    <React.Fragment>
      <FormGroup>
        <Flex sx={{ justifyContent: "space-between", alignItems: "center" }}>
          <FormLabel htmlFor="switch-show-pointer">
            {t("SPREADER.SETTINGS.MODAL_SHOW_POINTER")}
          </FormLabel>
          <Switch
            id="switch-show-pointer"
            selected={showPointer}
            onChange={handleSettingChange("showPointer")}
          />
        </Flex>
        {showPointer && (
          <Card
            sx={{
              display: "flex",
              flexDirection: "column",
              flexGrow: 1,
              gap: "sm",
            }}
          >
            <FormGroup>
              <FormLabel>
                {t("SPREADER.SETTINGS.MODAL_WORDS_PER_MINUTE_LABEL")}
              </FormLabel>
              <Flex sx={{ gap: "sm", alignItems: "center" }}>
                <NumberInput
                  sx={{ flex: "0 0 16.66667%" }}
                  value={wordsPerMinute}
                  onChange={handleSettingChange("wordsPerMinute")}
                  step={wordsPerMinuteStep}
                  min={wordsPerMinuteMin}
                  max={wordsPerMinuteMax}
                />
                <Flex sx={{ flexDirection: "column", flex: 1 }}>
                  <Slider
                    value={wordsSliderValue}
                    onChange={handleSettingChange("wordsPerMinute")}
                    step={wordsPerMinuteStep}
                    min={wordsPerMinuteMin}
                    max={wordsPerMinuteMax}
                  />
                  <Flex sx={{ justifyContent: "space-between" }}>
                    <SliderRangeLabel>{wordsPerMinuteMin}</SliderRangeLabel>
                    <SliderRangeLabel>{wordsPerMinuteMax}</SliderRangeLabel>
                  </Flex>
                </Flex>
              </Flex>
            </FormGroup>
            <FormGroup>
              <FormLabel>{t("SPREADER.SETTINGS.MODAL_POINTER_TYPE")}</FormLabel>
              <RadioButtonGroup>
                <RadioButton
                  label={t("SPREADER.SETTINGS.MODAL_POINTER_TYPE_LINEAR")}
                  name="pointerType"
                  value="linear"
                  checked={pointerType === "linear"}
                  onChange={handleSettingChange("pointerType")}
                />
                <RadioButton
                  label={t("SPREADER.SETTINGS.MODAL_POINTER_TYPE_PUNCTUAL")}
                  name="pointerType"
                  value="punctual"
                  checked={pointerType === "punctual"}
                  onChange={handleSettingChange("pointerType")}
                />
              </RadioButtonGroup>
            </FormGroup>
            {pointerType === "linear" && (
              <FormGroup>
                <FormLabel>
                  {t("SPREADER.SETTINGS.MODAL_LINEAR_POINTER_WIDTH_LABEL")}
                </FormLabel>
                <Flex sx={{ gap: "sm", alignItems: "center" }}>
                  <NumberInput
                    sx={{ flex: "0 0 16.66667%" }}
                    value={linearPointerWidthPercent}
                    onChange={handleSettingChange("linearPointerWidthPercent")}
                    step={linearPointerWidthPercentStep}
                    min={linearPointerWidthPercentMin}
                    max={linearPointerWidthPercentMax}
                  />
                  <Flex sx={{ flexDirection: "column", flex: 1 }}>
                    <Slider
                      value={linearPointerWidthPercentSliderValue}
                      onChange={handleSettingChange("linearPointerWidthPercent")}
                      step={linearPointerWidthPercentStep}
                      min={linearPointerWidthPercentMin}
                      max={linearPointerWidthPercentMax}
                    />
                    <Flex sx={{ justifyContent: "space-between" }}>
                      <SliderRangeLabel>{linearPointerWidthPercentMin}%</SliderRangeLabel>
                      <SliderRangeLabel>{linearPointerWidthPercentMax}</SliderRangeLabel>
                    </Flex>
                  </Flex>
                </Flex>
              </FormGroup>
            )}
            {pointerType === "punctual" && (
              <FormGroup>
                <FormLabel>
                  {t("SPREADER.SETTINGS.MODAL_PUNCTUAL_POINTER_POINTS_AMOUNT")}
                </FormLabel>
                <RadioButtonGroup>
                  <RadioButton
                    label={1}
                    name="punctualPointsAmount"
                    value="1"
                    checked={punctualPointsAmount === "1"}
                    onChange={handleSettingChange("punctualPointsAmount")}
                  />
                  <RadioButton
                    label={2}
                    name="punctualPointsAmount"
                    value="2"
                    checked={punctualPointsAmount === "2"}
                    onChange={handleSettingChange("punctualPointsAmount")}
                  />
                  <RadioButton
                    label={3}
                    name="punctualPointsAmount"
                    value="3"
                    checked={punctualPointsAmount === "3"}
                    onChange={handleSettingChange("punctualPointsAmount")}
                  />
                  <RadioButton
                    label={4}
                    name="punctualPointsAmount"
                    value="4"
                    checked={punctualPointsAmount === "4"}
                    onChange={handleSettingChange("punctualPointsAmount")}
                  />
                </RadioButtonGroup>
              </FormGroup>
            )}
          </Card>
        )}
      </FormGroup>
      <FormGroup>
        <FormLabel>{t("SPREADER.SETTINGS.MODAL_COLUMN_WIDTH_LABEL")}</FormLabel>
        <Flex sx={{ gap: "sm", alignItems: "center" }}>
          <NumberInput
            sx={{ flex: "0 0 16.66667%" }}
            value={columnWidthPercent}
            onChange={handleSettingChange("columnWidthPercent")}
            step={columnWidthPercentStep}
            min={columnWidthPercentMin}
            max={columnWidthPercentMax}
          />
          <Flex sx={{ flexDirection: "column", flex: 1 }}>
            <Slider
              value={columnWidthPercentSliderValue}
              onChange={handleSettingChange("columnWidthPercent")}
              step={columnWidthPercentStep}
              min={columnWidthPercentMin}
              max={columnWidthPercentMax}
            />
            <Flex sx={{ justifyContent: "space-between" }}>
              <SliderRangeLabel>{columnWidthPercentMin}%</SliderRangeLabel>
              <SliderRangeLabel>{columnWidthPercentMax}%</SliderRangeLabel>
            </Flex>
          </Flex>
        </Flex>
      </FormGroup>
    </React.Fragment>
  );
};
