/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from "theme-ui";
import { useCallback } from "react";

import { TTheme } from "theme";
import {
  getResponsiveStyles,
  TResponsiveValue,
  memoize,
  forwardRefWithAs,
  PropsWithAs,
} from "theme/utils";

export type TextSize = keyof TTheme["text"]["sizes"];
export type TextColor = keyof TTheme["colors"];

export interface TextComponentProps {
  as?: React.ElementType;
  color?: TextColor;
  size?: TextSize | TResponsiveValue<TextSize>;
  ellipsis?: boolean;
}

const getKey = (
  _theme: TTheme["text"]["sizes"],
  size: TextSize | TResponsiveValue<TextSize>
) => {
  return Array.isArray(size) ? size.join("-") : size;
};

const getSize = memoize(getResponsiveStyles, getKey);

const useStyles = (props: TextComponentProps) => {
  const { ellipsis, size, color } = props;

  return useCallback(
    (theme: TTheme) => {
      return {
        ...(size ? getSize(theme.text.sizes, size) : undefined),
        ...(color ? { color: theme.colors[color] } : undefined),
        ...(ellipsis
          ? {
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
              overflowX: "hidden",
            }
          : undefined),
      };
    },
    [ellipsis, size, color]
  );
};

export const TextComponent = (
  props: PropsWithAs<TextComponentProps, "span">,
  forwardedRef: React.Ref<HTMLSpanElement>
) => {
  const {
    as: Component = "span",
    children,
    size,
    color,
    ellipsis,
    ...rest
  } = props;

  const sxFun = useStyles(props);

  return (
    <Component sx={sxFun} ref={forwardedRef} {...rest}>
      {children}
    </Component>
  );
};

export type TextProps = PropsWithAs<TextComponentProps, "span">;
export const Text = forwardRefWithAs<TextComponentProps, "span">(TextComponent);
