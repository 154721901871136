/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from 'theme-ui';
import { useCallback } from "react";
import { TModeColor, useColorMode } from 'theme';

interface Props {}

const styles = {
  fontWeight: "bolder",
  fontSize: "12px",
  marginBottom: "xs",
  display: "inline-block",
}

const colorModeStyles: Record<TModeColor, {}> = {
  default: {
    color: "n50",
  },
  dark: {
    color: "n5",
  }
};

const useStyles = (props: Props) => {
  const [colorMode] = useColorMode();

  return useCallback(
    () => {
      return {
        ...styles,
        ...colorModeStyles[colorMode],
      };
    },
    [colorMode]
  );
};

export const Label: React.FC<Props> = (props) => {
  const { children } = props;
  const sx = useStyles(props);
  return (
    <label sx={sx}>{children}</label>
  );
};
