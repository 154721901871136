/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from "theme-ui";
import React from "react";

import { IStaticRenderState, ISettings } from "../types";
import { BoardContainer } from "./BoardContainer";
import { BoardInnerContainer } from "./BoardInnerContainer";

import { useInnerBoardContainerStyles } from "./useInnerBoardContainerStyles";

interface Props {
  renderState: IStaticRenderState;
  settings: ISettings;
}

export const StaticNoPointerBoard: React.FC<Props> = React.memo(
  ({ renderState, settings }) => {
    const { columnWidthPercent } = settings;

    const html = renderState.html;

    const boardInneContainerSx = useInnerBoardContainerStyles({ columnWidthPercent, mode: "static" });

    return (
      <BoardContainer settings={settings} mode={renderState.type}>
        <BoardInnerContainer sx={boardInneContainerSx}>
          <div data-text dangerouslySetInnerHTML={{ __html: `${html}` }} />
        </BoardInnerContainer>
      </BoardContainer>
    );
  }
);
