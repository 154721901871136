/** @jsxRuntime classic */
/** @jsx jsx */
import { useColorMode } from "theme";
import { jsx } from "theme-ui";

import { ICell, IBoardSettings, IBoardRenderState } from "./types";

interface ICellProps extends ICell {}

export const Cell: React.FC<ICellProps> = ({ value }) => {
  const [colorMode] = useColorMode();

  return (
    <div
      sx={{
        py: "22px",
        bg: colorMode === "default" ? "n10" : "n80",
        borderRadius: "lg",
        fontSize: "24px",
        fontWeight: "bold",
        textAlign: "center",
      }}
    >
      {value}
    </div>
  );
};

interface BoardProps {
  settings: IBoardSettings;
  renderState: IBoardRenderState;
}

export const Board: React.FC<BoardProps> = ({ settings, renderState }) => {
  return (
    <div
      sx={{
        display: "grid",
        gap: "xs",
        gridTemplateRows: `repeat(${renderState.board.length}, 1fr)`,
        gridTemplateColumns: `repeat(${renderState.board[0].length}, 1fr)`,
      }}
    >
      {renderState.board.map((row, rowIndex) =>
        row.map((col: ICell, colIndex) => (
          <Cell key={`${rowIndex}-${colIndex}`} {...col} />
        ))
      )}
    </div>
  );
};
