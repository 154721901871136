import { I18nextProvider } from "react-i18next";
import { BrowserRouter as Router } from "react-router-dom";

import { ThemeProvider } from "theme";

import { useGA } from "utils";

import { i18nInstance } from "./locales/i18n";

import { Pages } from "./views/Pages";
import { LangProvider } from "views/components/Locales";

import React from "react";

import './theme/fonts/opendyslexic-regular-webfont.woff2';
import './theme/fonts/opendyslexic-bold-webfont.woff2';

import './fonts.css'
import './App.css'

const Analitics = () => {
  useGA();
  return null;
}

function App() {

  return (
    <React.Fragment>
      <I18nextProvider i18n={i18nInstance}>
        <LangProvider>
          <Router>
            <ThemeProvider>
              <Analitics />
              <Pages />
            </ThemeProvider>
          </Router>
        </LangProvider>
      </I18nextProvider>
    </React.Fragment>
  );
}

export default App;
