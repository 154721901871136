
export const colors = {
  n0: "#FFFFFF",

  n5: "#F8F8F8",
  n10: "#EEEEEE",
  n20: "#C5C5C5",
  n50: "#696868",
  n60: "#484747",
  n70: "#3C3B3B",
  n80: "#323131",
  n90: "#2A2929",

  yellow: "#F7C750",
  yellow10: "#F4B211",

  bodyBg: "#FFFFFF",    // n0
  bodyColor: "#2A2929", // n90

  modes: {
    dark: {
      bodyBg: "#2A2929",    // n90
      bodyColor: "#FFFFFF", // n0
    }
  }
};

export type TColor = keyof typeof colors;
