import { Route, Routes, useNavigate } from "react-router-dom";

import { PageLayout } from "components/Page";

import { Topbar } from "views/components/Topbar";

import { ExercisesPage } from "views/Exercises";
import { SpreaderPage } from "views/Exercises/Spreader";
import { SchultzBoardsPage } from "views/Exercises/SchultzBoards";
import { ContactPage } from "views/Contact";

import { PATHS } from "./paths";
import { useEffect } from "react";

const Redirect = () => {
  const navigate = useNavigate();

  useEffect(() => {
    navigate(PATHS.EXERCISES.ROOT.toPath());
  }, [navigate]);

  return null;
}

export const Pages = () => {

  return (
    <PageLayout>
      <Topbar />
      <Routes>
        <Route path={PATHS.EXERCISES.ROOT.PATH} element={<ExercisesPage />} />
        <Route path={PATHS.EXERCISES.SPREADER.ROOT.PATH} element={<SpreaderPage />} />
        <Route path={PATHS.EXERCISES.SCHULTZ_BOARDS.ROOT.PATH} element={<SchultzBoardsPage />} />
        <Route path={PATHS.CONTACT.ROOT.PATH} element={<ContactPage />} />
        <Route path="*" element={<Redirect />} />
      </Routes>
    </PageLayout>
  );
};
