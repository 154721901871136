import { ThemeUIStyleObject } from "theme-ui";

export const icons = {
  sizes: {
    xl: {
      fontSize: "44px",
    },
    lg: {
      fontSize: "32px",
    },
    md: {
      fontSize: "24px",
    },
    sm: {
      fontSize: "20px",
    },
    xs: {
      fontSize: "16px",
    },
  },
};

export const baseIconStyles: ThemeUIStyleObject = {
  userSelect: "none",
  flexShrink: 0,
  lineHeight: "inherit",
  height: "1em",
  width: "1em",
  fill: "currentColor",
};
