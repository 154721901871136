import { fisherYatesShuffle } from "./fisherYatesShuffle";

export const randomizeText = (text: string) => {
  const firstChar = text[0];
  const lastChar = text[text.length - 1];
  const shuffled = fisherYatesShuffle(text.split('').slice(1, text.length - 1), text.length).join('');

  return `${firstChar}${shuffled}${lastChar}`;
}

export const randomizeWord = (word: string) => {
  if (word.length < 4) {
    return word;
  }
  return randomizeText(word);
}
