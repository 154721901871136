/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from "theme-ui";
import { useEffect, useState, useCallback, useRef } from "react";
import { useDialogState } from "reakit/Dialog";

import { setItem, getItem } from "utils";

import { PageContainer, PageContent } from "components/Page";

import { SpreaderBar } from "./SpreaderBar";
import { DynamicBoard } from "./Board/DynamicBoard";
import { StaticLinearPointerBoard } from "./Board/StaticLinearPointerBoard";
import { StaticNoPointerBoard } from "./Board/StaticNoPointerBoard";
import { StaticPunctualPointerBoard } from "./Board/StaticPunctualPointerBoard";

import { text } from "../../../texts/lesson1";
import { contentStyles, containerStyles } from "./styles";
import { ISettings, TActionState, IRenderState } from "./types";
import { createState, createRenderState } from "./service";

const defaultSettings: ISettings = {
  editableContent: text,
  mode: "dynamic",

  hideWords: false,
  hiddenWordsPercentage: 25,
  hideChars: false,
  hiddenCharsPercentage: 25,
  randomCharsOrder: false,
  upsideDownWords: false,

  wordsPerMinute: 300,
  wordsPerScreen: 4,
  sentenceOnNewScreen: true,
  extraPausaAfterParagraph: true,
  textPosition: "center",

  showPointer: true,
  columnWidthPercent: 100,
  pointerType: "linear",
  linearPointerWidthPercent: 85,
  punctualPointsAmount: 3,

  fontType: "modern",
  fontSize: "md",
};

const storageKey = "spreader-settings";
const { state: spreaderState, settings: spreaderSettings } = createState(defaultSettings, getItem(storageKey));

export const SpreaderPage = () => {
  const [renderState, setRenderState] =
    useState<IRenderState>(spreaderState);
  const [settings, setSettings] = useState<ISettings>(spreaderSettings);

  const [actionState, setActionState] = useState<TActionState>("PAUSE");
  const [index, setIndex] = useState<number>(0);
  const [indexMax, setIndexMax] = useState(0);

  const dialogState = useDialogState({ animated: true });

  const pause = useCallback(() => {
    if (actionState !== "PAUSE") {
      setActionState("PAUSE");
    }
  }, [actionState]);

  const play = useCallback(() => {
    if (index === indexMax) {
      return;
    }
    if (actionState !== "PLAY") {
      setActionState("PLAY");
    }
  }, [actionState, index, indexMax]);

  useEffect(() => {
    if (dialogState.visible) {
      pause();
    }
  }, [pause, dialogState.visible]);

  useEffect(() => {
    if (index === indexMax) {
      pause();
    }
  }, [index, indexMax, pause]);

  const moveForward = useCallback(() => {
    if (index < indexMax) {
      setIndex(index + 1);
    }
  }, [index, indexMax]);

  const moveBackward = useCallback(() => {
    if (index > 0) {
      setIndex(index - 1);
    }
  }, [index]);

  const handlePlayOrPauseClick = useCallback(() => {
    if (actionState === "PLAY") {
      pause();
    }
    if (actionState === "PAUSE") {
      play();
    }
  }, [actionState, pause, play]);

  useEffect(() => {
    const handleKeyDownEvent = (ev: KeyboardEvent) => {
      if (dialogState.visible) {
        return;
      }

      switch (ev.code) {
        case "ArrowRight":
          if (document.activeElement?.getAttribute("role") === "slider") {
            return;
          }
          moveForward();
          break;
        case "ArrowLeft":
          if (document.activeElement?.getAttribute("role") === "slider") {
            return;
          }
          moveBackward();
          break;
        case "Space":
          if (document.activeElement?.getAttribute("data-action-button")) {
            return;
          }

          handlePlayOrPauseClick();
          ev.preventDefault(); // prevent scrolling down
          break;
      }
    };

    document.addEventListener("keydown", handleKeyDownEvent);

    return () => {
      document.removeEventListener("keydown", handleKeyDownEvent);
    };
  }, [
    dialogState.visible,
    moveBackward,
    moveForward,
    handlePlayOrPauseClick,
    pause,
    play,
  ]);

  const handlePrevClick = () => {
    pause();
    setIndex(0);
  };

  const handleSettingsChange = (newSettings: ISettings) => {
    const newRenderState = createRenderState(newSettings)
    const oldRenderState = renderState;
    const oldIndex = index;

    setItem(storageKey, newSettings);
    setSettings(newSettings);
    setRenderState(newRenderState);

    if (settings.mode !== newSettings.mode) {
      setIndex(0);
      return;
    }

    if (settings.showPointer !== newSettings.showPointer) {
      setIndex(0);
      return;
    }

    if (settings.pointerType !== newSettings.pointerType) {
      setIndex(0);
      return;
    }

    if (settings.columnWidthPercent !== newSettings.columnWidthPercent) {
      setIndex(0);
      return;
    }

    if (settings.linearPointerWidthPercent !== newSettings.linearPointerWidthPercent) {
      setIndex(0);
      return;
    }

    if (settings.punctualPointsAmount !== newSettings.punctualPointsAmount) {
      setIndex(0);
      return;
    }

    if (!newRenderState.mode.numberOfWords) {
      setIndex(0);
      return;
    }

    if (
      oldRenderState.mode.numberOfWords !== newRenderState.mode.numberOfWords
    ) {
      setIndex(0);
      return;
    }

    if (
      oldRenderState.mode.type === "dynamic" &&
      newRenderState.mode.type === "dynamic"
    ) {
      let newIndex = 0;
      const alreadyReadWords = oldRenderState.mode.chunks[oldIndex].wordsRead;
      while (
        alreadyReadWords - settings.wordsPerScreen >=
        newRenderState.mode.chunks[newIndex].wordsRead
      ) {
        newIndex += 1;
      }

      setIndex(newIndex);
      return;
    }
  };

  const handleProgressChange = (value: number | number[]) => {
    if (!Array.isArray(value)) {
      setIndex(value);
    }
  };

  const keepActionStateForProgress = useRef<TActionState>(actionState);

  const handleProgressBeforeChange = () => {
    keepActionStateForProgress.current = actionState;
    if (actionState === "PLAY") {
      pause();
    }
  }

  const handleProgressAfterChange = () => {
    setActionState(keepActionStateForProgress.current);
  }

  return (
    <PageContainer sx={containerStyles}>
      <PageContent sx={contentStyles}>
        {renderState.mode.type === "dynamic" && (
          <DynamicBoard
            renderState={renderState.mode}
            actionState={actionState}
            settings={settings}
            index={index}
            onIndexChange={setIndex}
            indexMax={indexMax}
            onIndexMaxChange={setIndexMax}
          />
        )}
        {renderState.mode.type === "static" && settings.showPointer && settings.pointerType === "linear" && (
          <StaticLinearPointerBoard
            renderState={renderState.mode}
            actionState={actionState}
            settings={settings}
            index={index}
            onIndexChange={setIndex}
            onIndexMaxChange={setIndexMax}
          />
        )}
        {renderState.mode.type === "static" && settings.showPointer && settings.pointerType === "punctual" && (
          <StaticPunctualPointerBoard
            renderState={renderState.mode}
            actionState={actionState}
            settings={settings}
            index={index}
            onIndexChange={setIndex}
            onIndexMaxChange={setIndexMax}
          />
        )}
        {renderState.mode.type === "static" && !settings.showPointer && (
          <StaticNoPointerBoard
            renderState={renderState.mode}
            settings={settings}
          />
        )}
        <SpreaderBar
          dialogState={dialogState}
          hideActionBar={
            settings.mode === "static" && settings.showPointer === false
          }
          settings={settings}
          defaultSettings={defaultSettings}
          onSettingsChange={handleSettingsChange}
          onPlayClick={handlePlayOrPauseClick}
          onPauseClick={handlePlayOrPauseClick}
          onPrevClick={handlePrevClick}
          onProgressChange={handleProgressChange}
          onProgressBeforeChange={handleProgressBeforeChange}
          onProgressAfterChange={handleProgressAfterChange}
          progress={index}
          progressMax={indexMax}
          actionState={actionState}
          wordsPerMinute={settings.wordsPerMinute}
        />
      </PageContent>
    </PageContainer>
  );
};
