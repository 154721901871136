/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, Flex } from "theme-ui";
import React from "react";
import { useTranslation } from "react-i18next";

import { Card } from "components/Card";
import { Switch } from "components/Switch";
import { NumberInput } from "components/NumberInput";
import { FormLabel, FormGroup } from "components/Form";
import { Slider, SliderRangeLabel } from "components/Slider";

import {
  ISettings,
} from "../types";

interface CommonModeSettingsProps {
  settings: ISettings;
  onChange: (settings: ISettings) => void;
}

export const CommonModeSettings: React.FC<CommonModeSettingsProps> = ({
  settings,
  onChange,
}) => {
  const { t } = useTranslation("exercises");

  const {
    hideWords,
    hiddenWordsPercentage,
    hideChars,
    hiddenCharsPercentage,
    randomCharsOrder,
    upsideDownWords,
  } = settings;

  const hiddenWordsPercentageMax = 100;
  const hiddenWordsPercentageMin = 0;
  const hiddenWordsPercentageStep = 5;
  const sliderHiddenWordsPercentageValue = Number(hiddenWordsPercentage);

  const hiddenCharsPercentageMax = 100;
  const hiddenCharsPercentageMin = 0;
  const hiddenCharsPercentageStep = 5;
  const sliderHiddenCharsPercentageValue = Number(hiddenCharsPercentage);

  const handleSettingChange = (name: string) => (value: any) => {
    onChange({ ...settings, [name]: value });
  };

  return (
    <React.Fragment>
      <FormGroup>
        <Flex sx={{ justifyContent: "space-between", alignItems: "center" }}>
          <FormLabel htmlFor="switch-hide-words">
            {t("SPREADER.SETTINGS.MODAL_HIDE_WORDS_LABEL")}
          </FormLabel>
          <Switch
            id="switch-hide-words"
            selected={hideWords}
            onChange={handleSettingChange("hideWords")}
          />
        </Flex>
        {hideWords && (
          <Card>
            <FormGroup>
              <FormLabel>
                {t("SPREADER.SETTINGS.MODAL_HIDDEN_WORDS_PERCENTAGE_LABEL")}
              </FormLabel>
              <Flex sx={{ gap: "sm", alignItems: "center" }}>
                <NumberInput
                  sx={{ flex: "0 0 16.66667%" }}
                  value={hiddenWordsPercentage}
                  onChange={handleSettingChange("hiddenWordsPercentage")}
                  step={hiddenWordsPercentageStep}
                  min={hiddenWordsPercentageMin}
                  max={hiddenWordsPercentageMax}
                />
                <Flex sx={{ flexDirection: "column", flex: 1 }}>
                  <Slider
                    value={sliderHiddenWordsPercentageValue}
                    onChange={handleSettingChange("hiddenWordsPercentage")}
                    step={hiddenWordsPercentageStep}
                    min={hiddenWordsPercentageMin}
                    max={hiddenWordsPercentageMax}
                  />
                  <Flex sx={{ justifyContent: "space-between" }}>
                    <SliderRangeLabel>
                      {hiddenWordsPercentageMin}
                    </SliderRangeLabel>
                    <SliderRangeLabel>
                      {hiddenWordsPercentageMax}
                    </SliderRangeLabel>
                  </Flex>
                </Flex>
              </Flex>
            </FormGroup>
          </Card>
        )}
      </FormGroup>

      <FormGroup>
        <Flex sx={{ justifyContent: "space-between", alignItems: "center" }}>
          <FormLabel htmlFor="switch-hide-chars">
            {t("SPREADER.SETTINGS.MODAL_HIDE_CHARS_LABEL")}
          </FormLabel>
          <Switch
            id="switch-hide-chars"
            selected={hideChars}
            onChange={handleSettingChange("hideChars")}
          />
        </Flex>
        {hideChars && (
          <Card>
            <FormGroup>
              <FormLabel>
                {t("SPREADER.SETTINGS.MODAL_HIDDEN_CHARS_PERCENTAGE_LABEL")}
              </FormLabel>
              <Flex sx={{ gap: "sm", alignItems: "center" }}>
                <NumberInput
                  sx={{ flex: "0 0 16.66667%" }}
                  value={hiddenCharsPercentage}
                  onChange={handleSettingChange("hiddenCharsPercentage")}
                  step={hiddenCharsPercentageStep}
                  min={hiddenCharsPercentageMin}
                  max={hiddenCharsPercentageMax}
                />
                <Flex sx={{ flexDirection: "column", flex: 1 }}>
                  <Slider
                    value={sliderHiddenCharsPercentageValue}
                    onChange={handleSettingChange("hiddenCharsPercentage")}
                    step={hiddenCharsPercentageStep}
                    min={hiddenCharsPercentageMin}
                    max={hiddenCharsPercentageMax}
                  />
                  <Flex sx={{ justifyContent: "space-between" }}>
                    <SliderRangeLabel>
                      {hiddenCharsPercentageMin}
                    </SliderRangeLabel>
                    <SliderRangeLabel>
                      {hiddenCharsPercentageMax}
                    </SliderRangeLabel>
                  </Flex>
                </Flex>
              </Flex>
            </FormGroup>
          </Card>
        )}
      </FormGroup>

      <Flex sx={{ justifyContent: "space-between", alignItems: "center" }}>
        <FormLabel htmlFor="switch-random-chars-order">
          {t("SPREADER.SETTINGS.MODAL_RANDOM_CHARS_ORDER_LABEL")}
        </FormLabel>
        <Switch
          id="switch-random-chars-order"
          selected={randomCharsOrder}
          onChange={handleSettingChange("randomCharsOrder")}
        />
      </Flex>

      <Flex sx={{ justifyContent: "space-between", alignItems: "center" }}>
        <FormLabel htmlFor="switch-upside-down-words">
          {t("SPREADER.SETTINGS.MODAL_UPSIDE_DOWN_WORDS_LABEL")}
        </FormLabel>
        <Switch
          id="switch-upside-down-words"
          selected={upsideDownWords}
          onChange={handleSettingChange("upsideDownWords")}
        />
      </Flex>
    </React.Fragment>
  );
};
