/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from "theme-ui";
import React, { useCallback } from "react";
import merge from "lodash.merge";

import { getResponsiveStyles, memoize, TResponsiveValue } from "theme/utils";

import { useColorMode, TModeColor, TTheme } from "theme";

const sizes = {
  md: {
    p: "xxs",
    "[data-radio-label]": {
      paddingY: "xxs",
    }
  },
  sm: {
    p: "xxxs",
    "[data-radio-label]": {
      paddingY: 0
    }
  }
}

export type Size = keyof typeof sizes;

const styles = {
  listStyleType: "none",
  borderRadius: "lg",
  display: "flex",
  gap: "xxs",
};

const colorModeStyles: Record<TModeColor, {}> = {
  default: {
    bg: "n10",
    color: "n90",
  },
  dark: {
    bg: "n60",
    color: "n0",
  },
};
interface Props {
  size?: Size;
}

const getKey = (
  _theme: TTheme,
  size: Size | TResponsiveValue<Size>
) => {
  return Array.isArray(size) ? size.join("-") : size;
};

const getSize = memoize(getResponsiveStyles, getKey);

const useStyles = (props: Props) => {
  const { size = "md" } = props;
  const [colorMode] = useColorMode();

  return useCallback((theme: TTheme) => {
    return merge(styles, colorModeStyles[colorMode], getSize(sizes, size));
  }, [colorMode, size]);
};

export const RadioButtonGroup: React.FC<Props> = (props) => {
  const { children } = props;
  const styles = useStyles(props);
  return <ul sx={styles}>{children}</ul>;
};
