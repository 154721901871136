/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from "theme-ui";
import React, {
  useEffect,
  useLayoutEffect,
  useRef,
  useCallback,
} from "react";

import { IStaticRenderState, ISettings, TActionState } from "../types";
import { BoardContainer } from "./BoardContainer";
import { BoardInnerContainer } from "./BoardInnerContainer";

import { useInnerBoardContainerStyles } from "./useInnerBoardContainerStyles";

import {
  linearPointerWidthPercentMax,
  linearPointerWidthPercentMin,
} from "../const";

const Snap = require("snapsvg-cjs");

interface Props {
  index: number;
  onIndexChange: (index: number) => void;
  onIndexMaxChange: (index: number) => void;
  renderState: IStaticRenderState;
  actionState: TActionState;
  settings: ISettings;
}

export const StaticLinearPointerBoard: React.FC<Props> = React.memo(
  ({ renderState, actionState, index, onIndexChange, onIndexMaxChange, settings }) => {
    const { linearPointerWidthPercent, columnWidthPercent } = settings;
    const linearPointerWidthPercentRef = useRef(linearPointerWidthPercent);
    linearPointerWidthPercentRef.current = linearPointerWidthPercent;

    const html = renderState.html;

    const actionStateRef = useRef(actionState);

    const indexRef = useRef(index);
    indexRef.current = index;

    const innerContainerRef = useRef<HTMLDivElement>(null);
    const pointerRef = useRef<HTMLDivElement>(null);
    const svgRef = useRef<SVGSVGElement>(null);
    const pointerPathRef = useRef<SVGPathElement>(null);

    const animationRef = useRef<any>(null);

    const getTempo = useCallback(
      ({ i }: { i: number }) => {
        return (
          (((100 - i) / 100) * renderState.numberOfWords * 60 * 1000) /
          settings.wordsPerMinute
        );
      },
      [renderState.numberOfWords, settings.wordsPerMinute]
    );

    const getPointerPath = () => {
      const s = Snap(svgRef.current);
      return s.select("[data-pointer-path]");
    };

    const transformPointer = useCallback(({ i }: { i: number }) => {
      const pointerPath = getPointerPath();
      const pointerPathLength = Snap.path.getTotalLength(pointerPath);
      const step = (i / 100) * pointerPathLength;

      const point = Snap.path.getPointAtLength(pointerPath, step);

      requestAnimationFrame(() => {
        if (pointerRef.current) {
          pointerRef.current.style.transform = `translate3d(${point.x}px, ${point.y}px, 0)`
        };
      });
    }, []);

    const setupSvg = useCallback(
      ({ width, height }: { width: number; height: number }) => {
        svgRef.current?.setAttribute("viewBox", `0 0 ${width} ${height}`);

        // consider caching the path result for the same render state and the same dimensions
        // when the columnWidth and linearPointerWidth change at the same time this run more than once

        const words: HTMLElement[] = Array.from(
          document.querySelectorAll("[data-word]")
        );

        if (!words.length) {
          pointerPathRef.current?.setAttribute("d", "");
          return;
        }

        const linearPointerWidth =
          linearPointerWidthPercentRef.current < linearPointerWidthPercentMin
            ? linearPointerWidthPercentMin
            : linearPointerWidthPercentRef.current >
              linearPointerWidthPercentMax
            ? linearPointerWidthPercentMax
            : linearPointerWidthPercentRef.current;

        const maringLeftRight = (100 - linearPointerWidth) / 2 / 100;
        const marginLeftRightValue = width * maringLeftRight;

        const pointerYOffset = (pointerRef.current?.offsetHeight || 0) / 2 * -1;

        let firstWordInLineIndex = 0;
        let lastWordInLineIndex = 0;

        const startingY = words[0].offsetTop + words[0].offsetHeight;

        let path = '';
        let lastY = startingY;

        const drawPartialPath = ({ xRight, xLeft, y, yOffset, marginLeftRigth }:
          { xRight: number, xLeft: number, y: number, yOffset: number, marginLeftRigth: number }) => {
          let partial = ''
          if (xRight - xLeft < 4 * marginLeftRightValue) {
            partial = partial + `L${marginLeftRightValue},${y + yOffset}`
          } else {
            partial = partial + `L${xLeft + marginLeftRightValue},${y + yOffset}`
            partial = partial + ` L${xRight - marginLeftRightValue},${y + yOffset}`
          }
          return partial;
        }

        for (let i = 0; i < words.length; i++) {
          const word = words[i];
          const y = word.offsetTop + word.offsetHeight;

          if (y !== lastY) {
            const firstWordInLineXLeft = words[firstWordInLineIndex].offsetLeft;
            const lastWordInLineXRight = words[lastWordInLineIndex].offsetLeft + words[lastWordInLineIndex].offsetWidth;

            path = path + ` ${drawPartialPath({ xRight: lastWordInLineXRight, xLeft: firstWordInLineXLeft, y: lastY, yOffset: pointerYOffset, marginLeftRigth: marginLeftRightValue })}`;

            firstWordInLineIndex = i;
          }

          lastWordInLineIndex = i;
          lastY = y;
        }

        const firstWordInLineXLeft = words[firstWordInLineIndex].offsetLeft;
        const lastWordInLineXRight = words[lastWordInLineIndex].offsetLeft + words[lastWordInLineIndex].offsetWidth;
        path = path + ` ${drawPartialPath({ xRight: lastWordInLineXRight, xLeft: firstWordInLineXLeft, y: lastY, yOffset: pointerYOffset, marginLeftRigth: marginLeftRightValue })}`;

        path = path.replace('L', 'M'); // replace only the first Line draw to Move sign

        pointerPathRef.current?.setAttribute("d", path);

        onIndexMaxChange(100);
        transformPointer({ i: indexRef.current });  // alternatively use onIndexChange
      },
      [transformPointer, onIndexMaxChange]
    );

    const innerContainerObserver = useRef(
      new ResizeObserver((entries) => {
        for (let entry of entries) {
          const cr = entry.contentRect;
          requestAnimationFrame(() => {
            setupSvg({ width: cr.width, height: cr.height });
          });
        }
      })
    );

    const createAnimationAndRun = useCallback(
      ({ i, tempo }: { i: number; tempo: number }) => {
        const pointerPathLength = Snap.path.getTotalLength(getPointerPath());
        const step = (i * pointerPathLength) / 100;

        const animation = Snap.animate(
          step,
          pointerPathLength,
          (step: number) => {
            if (actionStateRef.current === "PLAY") {  // it fixes the last render before pause click
              onIndexChange((step / pointerPathLength) * 100);
            }
          },
          tempo
        );

        return animation;
      },
      [onIndexChange]
    );

    useLayoutEffect(() => {
      if (!innerContainerRef.current) {
        return;
      }

      const observer = innerContainerObserver.current;
      const innerContainer = innerContainerRef.current;
      observer.observe(innerContainer);

      return () => {
        observer.unobserve(innerContainer);
      };
    }, [renderState.numberOfWords, linearPointerWidthPercent, columnWidthPercent]);

    useEffect(() => {
      transformPointer({ i: index });
    }, [index, transformPointer]);

    useEffect(() => {
      if (actionStateRef.current === actionState) {
        return;
      }

      actionStateRef.current = actionState;

      if (actionState === "PLAY") {
        animationRef.current = createAnimationAndRun({
          i: index,
          tempo: getTempo({ i: index }),
        });
      }

      if (actionState === "PAUSE") {
        animationRef.current.stop();
      }
    }, [actionState, index, getTempo, createAnimationAndRun]);

    const boardInneContainerSx = useInnerBoardContainerStyles({ columnWidthPercent, mode: "static" });

    return (
      <BoardContainer settings={settings} mode={renderState.type}>
        <BoardInnerContainer ref={innerContainerRef} sx={boardInneContainerSx}>
          <svg data-pointer-svg ref={svgRef}>
            <path data-pointer-path ref={pointerPathRef} />
          </svg>
          <div data-text dangerouslySetInnerHTML={{ __html: `${html}` }} />
          {Boolean(renderState.numberOfWords) && (
            <div data-pointer ref={pointerRef} />
          )}
        </BoardInnerContainer>
      </BoardContainer>
    );
  }
);
