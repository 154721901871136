/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from "theme-ui";
import React, { useRef, useEffect } from "react";

import { BoardContainer } from "./BoardContainer";
import { ChunksRenderer } from "./ChunksRenderer";

import { ISettings, TActionState, IDynamicRenderState } from "../types";

interface Props {
  renderState: IDynamicRenderState;
  index: number;
  onIndexChange: (index: number) => void;
  onIndexMaxChange: (index: number) => void;
  indexMax: number;
  actionState: TActionState;
  settings: ISettings;
}

export const DynamicBoard: React.FC<Props> = ({
  renderState,
  index,
  onIndexChange,
  onIndexMaxChange,
  indexMax,
  settings,
  actionState,
}) => {
  const timeoutRef = useRef<NodeJS.Timeout>();
  const actionStateRef = useRef(actionState);
  const indexRef = useRef(index);
  indexRef.current = index;

  const { textPosition } = settings;

  const html = renderState.chunks[index]?.html || '';

  useEffect(() => {
    if (!renderState.numberOfWords) {
      return;
    }

    if (actionStateRef.current === actionState) {
      return;
    }

    actionStateRef.current = actionState;
    const chunks = renderState.chunks;

    const pause = () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };

    const run = () => {
      if (indexRef.current < indexMax) {
        onIndexChange(indexRef.current + 1);

        const tempo =
          renderState.tempo +
          renderState.tempo * chunks[indexRef.current].extraDelay;

        timeoutRef.current = setTimeout(run, tempo);
      }
    };

    if (actionState === "PLAY") {
      run();
    } else if (actionState === "PAUSE") {
      pause();
    }

    return () => {
      pause();
    }
  }, [actionState, indexMax, renderState, onIndexChange]);

  useEffect(() => {
    onIndexMaxChange(Math.max(renderState.chunks.length - 1, 0));
  }, [onIndexMaxChange, renderState.chunks.length])

  return (
    <BoardContainer settings={settings} mode={renderState.type}>
      <ChunksRenderer html={html} textPosition={textPosition} />
    </BoardContainer>
  );
};
