/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from "theme-ui";
import React from "react";
import { useTranslation } from "react-i18next";

import { DialogStateReturn } from "reakit/Dialog";

import { Modal } from "components/Dialogs/Modal";

import { IBoardSettings } from "../types";
import { BoardSettingsModalContent } from "./BoardSettingsModalContent";

interface BoardSettingsModalProps {
  defaultSettings: IBoardSettings;
  settings: IBoardSettings;
  onSettingsChange: (settigns: IBoardSettings) => void;
  dialogState: DialogStateReturn;
  activeTabIndex: number;
  setActiveTabIndex: (val: number) => void;
}

export const BoardSettingsModal: React.FC<BoardSettingsModalProps> = ({
  defaultSettings,
  settings,
  onSettingsChange,
  dialogState,
  activeTabIndex,
  setActiveTabIndex
}) => {
  const { t } = useTranslation("exercises");

  const handleSubmitClick = (settings: IBoardSettings) => {
    onSettingsChange(settings);
    dialogState.hide();
  };

  return (
    <Modal
      aria-label={t("SCHULTZ_BOARD.SETTINGS.MODAL_ARIA_LABEL")}
      {...dialogState}
    >
      <BoardSettingsModalContent
        defaultSettings={defaultSettings}
        settings={settings}
        onCloseClick={dialogState.hide}
        onSubmitClick={handleSubmitClick}
        activeTabIndex={activeTabIndex}
        setActiveTabIndex={setActiveTabIndex}
      />
    </Modal>
  );
};
