/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from "theme-ui";
import React, { useRef, useLayoutEffect, useEffect } from "react";
import { TTextPosition } from "../types";

import { BoardInnerContainer } from "./BoardInnerContainer";

import { useInnerBoardContainerStyles } from "./useInnerBoardContainerStyles";

interface Props {
  html: string;
  textPosition: TTextPosition;
}

export const ChunksRenderer: React.FC<Props> = React.memo((props) => {
  const { html, textPosition } = props;

  const innerContainerRef = useRef<HTMLDivElement>(null);
  const chunkRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!(chunkRef && chunkRef.current)) {
      return
    }

    if (textPosition === "center") {
      chunkRef.current.removeAttribute('style');
    }
  }, [textPosition])

  useLayoutEffect(() => {
    if (!(chunkRef && chunkRef.current)) {
      return
    }

    if (!(innerContainerRef && innerContainerRef.current)) {
      return
    }

    if (textPosition === "random") {
      const container = innerContainerRef.current;
      const chunk = chunkRef.current;

      const x = Math.floor(
        Math.random() * (container.offsetWidth - chunk.offsetWidth)
      );
      const y = Math.floor(
        Math.random() * (container.offsetHeight - chunk.offsetHeight)
      );

      chunkRef.current.style.position = "absolute";
      chunkRef.current.style.transform = `translate3d(${x}px, ${y}px, 0)`;
    }
  });

  const sx = useInnerBoardContainerStyles({ mode: "dynamic" })

  return (
    <BoardInnerContainer ref={innerContainerRef} sx={sx}>
      <div
        ref={chunkRef}
        data-chunk
        dangerouslySetInnerHTML={{ __html: `${html}` }}
      />
    </BoardInnerContainer>
  );
});

