/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from "theme-ui";
import React from "react";

import { Button, ButtonProps } from "./../Button";
import { CloseIcon } from "./../Icons";

export interface DialogCloseButtonProps extends ButtonProps {}

export const DialogCloseButton = React.forwardRef<
  HTMLButtonElement,
  DialogCloseButtonProps
>((props, ref) => {
  return (
    <Button
      type="button"
      size="sm"
      variant="ghost"
      iconLeft={<CloseIcon />}
      {...props}
      ref={ref}
    />
  );
});
