import { ThemeUIStyleObject } from "@theme-ui/css";

import { TModeColor } from 'theme';

const sliderRailKey = ".rc-slider-rail";
const sliderHandleKey = ".rc-slider-handle";
const focusHoverKey = "&:focus, &:hover";

export const styles: ThemeUIStyleObject = {
  "&.rc-slider": {
    position: "relative",
    width: "100%",
    height: "19px",
    touchAction: "none",
    boxSizing: "border-box",

    [sliderRailKey]: {
      position: "absolute",
      top: "calc(50% - 1px)",
      width: "100%",
      height: "2px",
      borderRadius: "2px",
    },

    ".rc-slider-track": {
      position: "absolute",
      top: "calc(50% - 2px)",
      height: "4px",
      borderRadius: "2px",
      backgroundColor: "yellow",
    },

    ".rc-slider-step": {
      position: "absolute",
      top: "calc(50% - 2px)",
      width: "100%",
      height: "2px",
      backgroundColor: "transparent",
    },

    [sliderHandleKey]: {
      position: "absolute",
      top: "calc(50% - 8px)",
      width: "16px",
      height: "16px",
      marginTop: "0",
      backgroundColor: "yellow",
      borderRadius: "50%",
      touchAction: "pan-x",
      outline: "none",

      [focusHoverKey]: {
        boxShadow: "0px 0px 0px 2px #F7C750",
      }

      // "&:hover": {
      //   top: "calc(50% - 8px)",
      //   width: "16px",
      //   height: "16px",
      //   backgroundColor: "yellow",
      // },
    },

    // "&.rc-slider-disabled": {
    //   ".rc-slider-track, .rc-slider-dot-active": {
    //     backgroundColor: "n300",
    //   },

    //   ".rc-slider-handle": {
    //     backgroundColor: "n200",
    //     top: "calc(50% - 7px)",
    //     width: "14px",
    //     height: "14px",
    //   },
    // },
  },
};

export const colorModeStyles: Record<TModeColor, {}> = {
  default: {
    [sliderRailKey]: {
      backgroundColor: "n20",
    },
  },
  dark: {
    [sliderRailKey]: {
      backgroundColor: "n50",
    },
  }
};
