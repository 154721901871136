/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from "theme-ui";
import React, { PropsWithChildren } from "react";
import { useColorMode } from 'theme';

export interface DialogFooterProps {}

export const DialogFooter = React.forwardRef<
  HTMLDivElement,
  PropsWithChildren<DialogFooterProps>
>((props, ref) => {
  const { children, ...rest } = props;

  const [colorMode] = useColorMode();

  return (
    <div
      sx={{
        bg: "inherit",
        display: "flex",
        p: "sm",
        borderTop: "1px solid",
        borderColor: colorMode === "default" ? "n10" : "n70",
        borderBottomLeftRadius: "lg",
        borderBottomRightRadius: "lg",
      }}
      {...rest}
      ref={ref}
      data-component="dialog-footer"
    >
      {children}
    </div>
  );
});
