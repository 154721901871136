/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from 'theme-ui';
import React from "react";

import { PropsWithAs } from "theme/utils";

interface Props {
  className?: string;
}

const styles = { margin: "0 auto", width: "100%", maxWidth: "1000px" };

export const PageContainer: React.FC<PropsWithAs<Props, "div" | "nav">> = ({
  children,
  as: Component = "div",
  className,
}) => {
  return (
    <Component sx={styles} className={className}>
      {children}
    </Component>
  );
};
