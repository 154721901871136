export const wordsPerMinuteMax = 5000;
export const wordsPerMinuteMin = 1;
export const wordsPerMinuteStep = 50;

export const columnWidthPercentMax = 100;
export const columnWidthPercentMin = 5;
export const columnWidthPercentStep = 5;

export const linearPointerWidthPercentMax = 100;
export const linearPointerWidthPercentMin = 5;
export const linearPointerWidthPercentStep = 5;
