/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from "theme-ui";
import React, { PropsWithChildren } from "react";
import { useColorMode } from 'theme';

import { DialogCloseButton } from "./DialogCloseButton";

export interface DialogHeaderProps {
  onCloseClick?: () => void;
  closeButtonAriaLabel?: string;
  headerPlaceholder: React.ReactNode;
}

export const DialogHeader = React.forwardRef<
  HTMLDivElement,
  PropsWithChildren<DialogHeaderProps>
>((props, ref) => {
  const {
    children,
    closeButtonAriaLabel,
    onCloseClick,
    headerPlaceholder,
    ...rest
  } = props;

  const [colorMode] = useColorMode();

  return (
    <div
      sx={{
        display: "flex",
        alignItems: "center",
        paddingX: "sm",
        justifyContent: "space-between",
        borderBottom: "1px solid",
        borderColor: colorMode === "default" ? "n10" : "n70",
        bg: "inherit",
        zIndex: 1,
        borderTopLeftRadius: "lg",
        borderTopRightRadius: "lg",
      }}
      {...rest}
      ref={ref}
    >
      {headerPlaceholder}
      {typeof onCloseClick === "function" &&
        typeof closeButtonAriaLabel === "string" && (
          <div sx={{ py: "xxs" }}>
            <DialogCloseButton
              size="sm"
              sx={{ ml: "xs" }}
              onClick={onCloseClick}
              aria-label={closeButtonAriaLabel}
            />
          </div>
        )}
    </div>
  );
});
