import { createIcon } from "./createIcon";

import { ReactComponent as BookSvg } from "./svg/book.svg";
import { ReactComponent as CloseSvg } from "./svg/close.svg";
import { ReactComponent as DynamicTextSvg } from "./svg/dynamictext.svg";
import { ReactComponent as EnvelopSvg } from "./svg/envelop.svg";
import { ReactComponent as GearSvg } from "./svg/gear.svg";
import { ReactComponent as LogoSensusSvg } from "./svg/logoSensus.svg";
import { ReactComponent as PlaySvg } from "./svg/play.svg";
import { ReactComponent as MenuSvg } from "./svg/menu.svg";
import { ReactComponent as PauseSvg } from "./svg/pause.svg";
import { ReactComponent as PreviousSvg } from "./svg/previous.svg";
import { ReactComponent as RefreshSvg } from "./svg/refresh.svg";
import { ReactComponent as StaticTextSvg } from "./svg/statictext.svg";
import { ReactComponent as TTFontSvg } from "./svg/ttfont.svg";

export const BookIcon = createIcon(BookSvg);
export const CloseIcon = createIcon(CloseSvg);
export const DynamicTextIcon = createIcon(DynamicTextSvg);
export const EnvelopIcon = createIcon(EnvelopSvg);
export const GearIcon = createIcon(GearSvg);
export const LogoSensus = createIcon(LogoSensusSvg);
export const PlayIcon = createIcon(PlaySvg);
export const MenuIcon = createIcon(MenuSvg);
export const PauseIcon = createIcon(PauseSvg);
export const PreviousIcon = createIcon(PreviousSvg);
export const RefreshIcon = createIcon(RefreshSvg);
export const StaticTextIcon = createIcon(StaticTextSvg);
export const TTFontIcon = createIcon(TTFontSvg);
