/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, Flex } from "theme-ui";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import { Button } from "components/Button";
import { GearIcon } from "components/Icons";
import { FormLabel, FormGroup } from "components/Form";
import { TabButton } from "components/TabButton";
import { RadioButton, RadioButtonGroup } from "components/RadioButton";

import { IBoardSettings } from "../types";
import {
  colsMax,
  colsMin,
  colsStep,
  rowsMax,
  rowsMin,
  rowsStep,
} from "../const";

import {
  ModalHeader,
  ModalContent,
  ModalFooter,
} from "components/Dialogs/Modal";
import { NumberInput } from "components/NumberInput";
import { Slider, SliderRangeLabel } from "components/Slider";

interface BoardSettingsModalContentProps {
  defaultSettings: IBoardSettings;
  settings: IBoardSettings;
  onCloseClick: () => void;
  onSubmitClick: (settings: IBoardSettings) => void;
  activeTabIndex: number;
  setActiveTabIndex: (val: number) => void;
}

export const BoardSettingsModalContent: React.FC<
  BoardSettingsModalContentProps
> = ({
  defaultSettings,
  settings,
  onCloseClick,
  onSubmitClick,
  activeTabIndex,
  setActiveTabIndex,
}) => {
  const { t } = useTranslation("exercises");

  const [modalSettings, setModalSettings] = useState<IBoardSettings>(settings);

  const { cols, rows, charsType } = modalSettings;

  const handleSettingChange = (name: string) => (value: any) => {
    setModalSettings({ ...modalSettings, [name]: value });
  };

  const handleSubmitClick = () => {
    onSubmitClick({
      ...modalSettings,
    });
  };

  const handleDefaultValuesClick = () => {
    setModalSettings(defaultSettings);
  };

  const colsSliderValue = Number(cols);
  const rowsSliderValue = Number(rows);

  return (
    <React.Fragment>
      <ModalHeader
        onCloseClick={onCloseClick}
        closeButtonAriaLabel="close-button-aria"
        headerPlaceholder={
          <Flex sx={{ overflowX: "auto" }}>
            <TabButton
              active={activeTabIndex === 0}
              placeholderLeft={<GearIcon />}
            >
              {t("SCHULTZ_BOARD.SETTINGS.MODAL_TAB_BUTTON_EXERCISE")}
            </TabButton>
          </Flex>
        }
      />

      <ModalContent>
        <Flex sx={{ flexDirection: "column", gap: "lg", flexGrow: 1 }}>
          <FormGroup>
            <FormLabel>
              {t("SCHULTZ_BOARD.SETTINGS.MODAL_COLS_LABEL")}
            </FormLabel>
            <Flex sx={{ gap: "sm", alignItems: "center" }}>
              <NumberInput
                sx={{ flex: "0 0 16.66667%" }}
                value={cols}
                onChange={handleSettingChange("cols")}
                step={colsStep}
                min={colsMin}
                max={colsMax}
              />
              <Flex sx={{ flexDirection: "column", flex: 1 }}>
                <Slider
                  value={colsSliderValue}
                  onChange={handleSettingChange("cols")}
                  step={colsStep}
                  min={colsMin}
                  max={colsMax}
                />
                <Flex sx={{ justifyContent: "space-between" }}>
                  <SliderRangeLabel>{colsMin}</SliderRangeLabel>
                  <SliderRangeLabel>{colsMax}</SliderRangeLabel>
                </Flex>
              </Flex>
            </Flex>
          </FormGroup>
          <FormGroup>
            <FormLabel>
              {t("SCHULTZ_BOARD.SETTINGS.MODAL_ROWS_LABEL")}
            </FormLabel>
            <Flex sx={{ gap: "sm", alignItems: "center" }}>
              <NumberInput
                sx={{ flex: "0 0 16.66667%" }}
                value={rows}
                onChange={handleSettingChange("rows")}
                step={rowsStep}
                min={rowsMin}
                max={rowsMax}
              />
              <Flex sx={{ flexDirection: "column", flex: 1 }}>
                <Slider
                  value={rowsSliderValue}
                  onChange={handleSettingChange("rows")}
                  step={rowsStep}
                  min={rowsMin}
                  max={rowsMax}
                />
                <Flex sx={{ justifyContent: "space-between" }}>
                  <SliderRangeLabel>{rowsMin}</SliderRangeLabel>
                  <SliderRangeLabel>{rowsMax}</SliderRangeLabel>
                </Flex>
              </Flex>
            </Flex>
          </FormGroup>
          <FormGroup>
            <FormLabel>
              {t("SCHULTZ_BOARD.SETTINGS.MODAL_CHARS_TYPE_LABEL")}
            </FormLabel>
            <RadioButtonGroup>
            <RadioButton
              label={t("SCHULTZ_BOARD.SETTINGS.MODAL_DIGITS_LABEL")}
              name="charsType"
              value="DIGITS"
              checked={charsType === "DIGITS"}
              onChange={handleSettingChange("charsType")}
            />
            <RadioButton
              label={t("SCHULTZ_BOARD.SETTINGS.MODAL_LETTERS_LABEL")}
              name="charsType"
              value="LETTERS"
              checked={charsType === "LETTERS"}
              onChange={handleSettingChange("charsType")}
            />
          </RadioButtonGroup>
        </FormGroup>
        </Flex>
      </ModalContent>

      <ModalFooter>
        <Button
          variant="secondary"
          size="sm"
          onClick={handleDefaultValuesClick}
        >
          {t("SCHULTZ_BOARD.SETTINGS.MODAL_DEFAULT_VALUES_BUTTON")}
        </Button>
        <Flex sx={{ gap: "sm" }}>
          <Button size="sm" variant="ghost" onClick={onCloseClick}>
            {t("SCHULTZ_BOARD.SETTINGS.MODAL_EXTRA_CANCEL_BUTTON")}
          </Button>
          <Button
            size="sm"
            type="submit"
            variant="primary"
            onClick={handleSubmitClick}
          >
            {t("SCHULTZ_BOARD.SETTINGS.MODAL_READ_BUTTON")}
          </Button>
        </Flex>
      </ModalFooter>
    </React.Fragment>
  );
};
