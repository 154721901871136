/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, Flex } from "theme-ui";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import { Button } from "components/Button";
import {
  BookIcon,
  GearIcon,
  TTFontIcon,
  DynamicTextIcon,
  StaticTextIcon,
} from "components/Icons";
import { ContentEditable } from "components/ContentEditable";
import { FormLabel, FormGroup } from "components/Form";
import { TabButton } from "components/TabButton";
import { RadioButton, RadioButtonGroup } from "components/RadioButton";

import { ISettings } from "../types";

import { settingsFontSizes, fontTypes } from "../styles";

import {
  ModalHeader,
  ModalContent,
  ModalFooter,
} from "components/Dialogs/Modal";

import { CommonModeSettings } from "./CommonModeSettings";
import { DynamicOnlySettings } from "./DynamicOnlySettings";
import { StaticOnlySettings } from "./StaticOnlySettings";

interface SpreaderSettingsModalContentProps {
  defaultSettings: ISettings;
  settings: ISettings;
  onCloseClick: () => void;
  onSubmitClick: (settings: ISettings) => void;

  activeTabIndex: number;
  setActiveTabIndex: (val: number) => void;
}

export const SpreaderSettingsModalContent: React.FC<
  SpreaderSettingsModalContentProps
> = ({
  defaultSettings,
  settings,
  onCloseClick,
  onSubmitClick,
  activeTabIndex,
  setActiveTabIndex,
}) => {
  const { t } = useTranslation("exercises");

  const [modalSettings, setModalSettings] = useState<ISettings>(settings);

  const { editableContent, mode, fontType, fontSize } = modalSettings;

  const handleSettingChange = (name: string) => (value: any) => {
    setModalSettings({ ...modalSettings, [name]: value });
  };

  const handlePartialSettingsChange = (partialSettings: ISettings) => {
    setModalSettings({
      ...modalSettings,
      ...partialSettings,
    });
  };

  const handleSubmitClick = () => {
    onSubmitClick({
      ...modalSettings,
    });
  };

  const handleDefaultValuesClick = () => {
    setModalSettings(defaultSettings);
  };

  return (
    <React.Fragment>
      <ModalHeader
        onCloseClick={onCloseClick}
        closeButtonAriaLabel="close-button-aria"
        headerPlaceholder={
          <Flex sx={{ overflowX: "auto" }}>
            <TabButton
              active={activeTabIndex === 0}
              onClick={() => setActiveTabIndex(0)}
              placeholderLeft={<BookIcon />}
            >
              {t("SPREADER.SETTINGS.MODAL_TAB_BUTTON_TEXT")}
            </TabButton>
            <TabButton
              active={activeTabIndex === 1}
              onClick={() => setActiveTabIndex(1)}
              placeholderLeft={<GearIcon />}
            >
              {t("SPREADER.SETTINGS.MODAL_TAB_BUTTON_EXERCISE")}
            </TabButton>
            <TabButton
              active={activeTabIndex === 2}
              onClick={() => setActiveTabIndex(2)}
              placeholderLeft={<TTFontIcon />}
            >
              {t("SPREADER.SETTINGS.MODAL_TAB_BUTTON_FONT")}
            </TabButton>
          </Flex>
        }
      />

      <ModalContent>
        <Flex sx={{ flexDirection: "column", gap: "lg", flexGrow: 1 }}>
          {activeTabIndex === 0 && (
            <FormGroup sx={{ flexGrow: 1 }}>
              <FormLabel>{t("SPREADER.SETTINGS.MODAL_TEXT_LABEL")}</FormLabel>
              <ContentEditable
                sx={{ flexGrow: 1, minHeight: "150px" }}
                html={editableContent}
                onChange={handleSettingChange("editableContent")}
              />
            </FormGroup>
          )}
          {activeTabIndex === 1 && (
            <React.Fragment>
              <FormGroup>
                <FormLabel>{t("SPREADER.SETTINGS.MODAL_MODE")}</FormLabel>
                <RadioButtonGroup>
                  <RadioButton
                    label={
                      <Flex
                        sx={{
                          flexDirection: "column",
                          alignItems: "center",
                        }}
                      >
                        <DynamicTextIcon sx={{ fontSize: "15px" }} />
                        <span>{t("SPREADER.SETTINGS.MODAL_MODE_DYNAMIC")}</span>
                      </Flex>
                    }
                    name="mode"
                    value="dynamic"
                    checked={mode === "dynamic"}
                    onChange={handleSettingChange("mode")}
                  />
                  <RadioButton
                    label={
                      <Flex
                        sx={{
                          flexDirection: "column",
                          alignItems: "center",
                        }}
                      >
                        <StaticTextIcon sx={{ fontSize: "13px" }} />
                        <span>{t("SPREADER.SETTINGS.MODAL_MODE_STATIC")}</span>
                      </Flex>
                    }
                    name="mode"
                    value="static"
                    checked={mode === "static"}
                    onChange={handleSettingChange("mode")}
                  />
                </RadioButtonGroup>
              </FormGroup>

              {modalSettings.mode === "static" && (
                <StaticOnlySettings
                  settings={modalSettings}
                  onChange={handlePartialSettingsChange}
                />
              )}
              {modalSettings.mode === "dynamic" && (
                <DynamicOnlySettings
                  settings={modalSettings}
                  onChange={handlePartialSettingsChange}
                />
              )}
              <CommonModeSettings
                key={modalSettings.mode}
                settings={modalSettings}
                onChange={handlePartialSettingsChange}
              />
            </React.Fragment>
          )}
          {activeTabIndex === 2 && (
            <React.Fragment>
              <FormGroup>
                <FormLabel>{t("SPREADER.SETTINGS.MODAL_FONT_TYPE")}</FormLabel>
                <RadioButtonGroup>
                  <RadioButton
                    sx={{ label: { fontFamily: fontTypes["modern"] } }}
                    label={t("SPREADER.SETTINGS.MODAL_FONT_TYPE_MODERN")}
                    name="fontType"
                    value="modern"
                    checked={fontType === "modern"}
                    onChange={handleSettingChange("fontType")}
                  />
                  <RadioButton
                    sx={{ label: { fontFamily: fontTypes["classic"] } }}
                    label={t("SPREADER.SETTINGS.MODAL_FONT_TYPE_CLASSIC")}
                    name="fontType"
                    value="classic"
                    checked={fontType === "classic"}
                    onChange={handleSettingChange("fontType")}
                  />
                  <RadioButton
                    sx={{ label: { fontFamily: fontTypes["dyslexic"] } }}
                    label={t("SPREADER.SETTINGS.MODAL_FONT_TYPE_DYSLEXIC")}
                    name="fontType"
                    value="dyslexic"
                    checked={fontType === "dyslexic"}
                    onChange={handleSettingChange("fontType")}
                  />
                </RadioButtonGroup>
              </FormGroup>

              <FormGroup>
                <FormLabel>{t("SPREADER.SETTINGS.MODAL_FONT_SIZE")}</FormLabel>
                <RadioButtonGroup>
                  <RadioButton
                    sx={{ label: { fontSize: settingsFontSizes["xs"] } }}
                    label={t("SPREADER.SETTINGS.MODAL_FONT_SIZE_XS")}
                    name="fontSize"
                    value="xs"
                    checked={fontSize === "xs"}
                    onChange={handleSettingChange("fontSize")}
                  />
                  <RadioButton
                    sx={{ label: { fontSize: settingsFontSizes["sm"] } }}
                    label={t("SPREADER.SETTINGS.MODAL_FONT_SIZE_SM")}
                    name="fontSize"
                    value="sm"
                    checked={fontSize === "sm"}
                    onChange={handleSettingChange("fontSize")}
                  />
                  <RadioButton
                    sx={{ label: { fontSize: settingsFontSizes["md"] } }}
                    label={t("SPREADER.SETTINGS.MODAL_FONT_SIZE_MD")}
                    name="fontSize"
                    value="md"
                    checked={fontSize === "md"}
                    onChange={handleSettingChange("fontSize")}
                  />
                  <RadioButton
                    sx={{ label: { fontSize: settingsFontSizes["lg"] } }}
                    label={t("SPREADER.SETTINGS.MODAL_FONT_SIZE_LG")}
                    name="fontSize"
                    value="lg"
                    checked={fontSize === "lg"}
                    onChange={handleSettingChange("fontSize")}
                  />
                  <RadioButton
                    sx={{ label: { fontSize: settingsFontSizes["xl"] } }}
                    label={t("SPREADER.SETTINGS.MODAL_FONT_SIZE_XL")}
                    name="fontSize"
                    value="xl"
                    checked={fontSize === "xl"}
                    onChange={handleSettingChange("fontSize")}
                  />
                </RadioButtonGroup>
              </FormGroup>
            </React.Fragment>
          )}
        </Flex>
      </ModalContent>

      <ModalFooter>
        <Button
          variant="secondary"
          size="sm"
          onClick={handleDefaultValuesClick}
        >
          {t("SPREADER.SETTINGS.MODAL_DEFAULT_VALUES_BUTTON")}
        </Button>
        <Flex sx={{ gap: "sm" }}>
          <Button size="sm" variant="ghost" onClick={onCloseClick}>
            {t("SPREADER.SETTINGS.MODAL_EXTRA_CANCEL_BUTTON")}
          </Button>
          <Button
            size="sm"
            type="submit"
            variant="primary"
            onClick={handleSubmitClick}
          >
            {t("SPREADER.SETTINGS.MODAL_READ_BUTTON")}
          </Button>
        </Flex>
      </ModalFooter>
    </React.Fragment>
  );
};
