import { hideLetters, hideWords, randomizeWord, splitWord } from "utils/text";

import { ISettings, TextStructure, IChunk, IRenderState } from "./types";

const WORD_WRAPPER_START = '<span data-word>';
const WORD_WRAPPER_END = "</span>";

const EXTRA_PAUSE_AFTER_PARAGRAPH_PERCENT = 0.2;

const applyCommonSetting = (words: string[], settings: ISettings): string[] => {
  let wordsChunk = [...words];
  if (settings.randomCharsOrder || (settings.hideChars && settings.hiddenCharsPercentage > 0)){
    wordsChunk = wordsChunk.map(word => {
      let [pre, text, suf] = splitWord(word);

      if (settings.randomCharsOrder) {
        text = randomizeWord(text);
      }

      if (settings.hideChars) {
        text = hideLetters(text, { hidePercent: settings.hiddenCharsPercentage });
      }

      return [pre, text, suf].join('');
    });
  }

  if (settings.hideWords && settings.hiddenWordsPercentage > 0) {
    wordsChunk = hideWords(wordsChunk, {
      hidePercent: settings.hiddenWordsPercentage,
    });
  }

  // if (settings.hidePunctuation) {
  //   wordsChunk = wordsChunk.map(word => {
  //     let [pre, text, suf] = splitWord(word);

  //     pre = settings.hidePunctuation ? hideText(pre) : pre;
  //     suf = settings.hidePunctuation ? hideText(suf) : suf;

  //     return [pre, text, suf].join('');
  //   })
  // }

  return wordsChunk;
}

// it assumes html containg only div, p, br, ul, ol, li tags (from ContentEditable component)
const getText = (rawWditableContent: string) => {
  const editableContent = rawWditableContent.trim()
    .replace(/>\s+|\s+</g, m => { // clean white chars between tags
        return m.trim();
    })

  const iterator = editableContent.matchAll(/<p>((.|\n)*?)<\/p>/g); // clean the inside paragraphs content

  let cleanHtml = '';
  let lastIndex = 0;
  let match = iterator.next();

  while (!match.done) {
    const { value } = match;

    cleanHtml = cleanHtml + value.input?.substring(lastIndex, value.index);
    // replace \r\n|\r|\n chars to ' ' and use the content witouth a <p> wrapper
    cleanHtml = cleanHtml + value[1].replaceAll(/(?:\r\n|\r|\n)/g, ' ') + `\n`;

    if (typeof value.index === 'number') {
      lastIndex = value.index + value[0].length;
    }

    match = iterator.next();
  }

  cleanHtml = cleanHtml + editableContent.substring(lastIndex, editableContent.length);

  const node = document.createElement("div"); // clean all other tags like divs and brs, keep the new line chars
  node.setAttribute('hidden', 'true');
  node.innerHTML = cleanHtml;
  const text = node.innerText;
  node.remove();

  return text.trim();
}

const prepareStaticHtml = (settings: ISettings) => {
  const PARAGRAPH_REGEXP_SPLIT = /(?:\r\n|\r|\n)/;
  const text = getText(settings.editableContent);

  let numberOfWords = 0;

  const html = text.split(PARAGRAPH_REGEXP_SPLIT).map(paragraph => {
    if (!paragraph) {
      return `<p data-paragraph><span data-empty>&nbsp;</span></p>`;
    }

    let words = paragraph.split(' ');

    numberOfWords = numberOfWords + words.length;

    words = applyCommonSetting(words, settings);
    words = words.map(word => `${WORD_WRAPPER_START}${word}${WORD_WRAPPER_END}`);

    return `<p data-paragraph>${words.join(` `)}</p>`;
  }
  ).join('');

  return {
    html,
    numberOfWords,
  }
}

const prepareDynamicTextStructure = (settings: ISettings): TextStructure => {
  const PARAGRAPH_REGEXP_SPLIT = /(?:\r\n|\r|\n)/;
  const SENTENCES_REGEXP_MATCH = /((?:[A-Z][a-z]\.|\w\.\w.|.)*?(?:[.!?]|$))(?:\s+|$)/g;

  const { sentenceOnNewScreen } = settings;
  const text = getText(settings.editableContent)

  const splitSentence = (sentence: string) => sentence.split(" ").filter(Boolean);

  return text
    .split(PARAGRAPH_REGEXP_SPLIT)
    .filter(Boolean)
    .map((paragraph) => {
      return sentenceOnNewScreen ? (paragraph.match(SENTENCES_REGEXP_MATCH) ?? []).filter(Boolean).map(splitSentence) : [paragraph].map(splitSentence)
    });
}

const prepareChunks = (paragraphs: TextStructure, settings: ISettings) => {
  const { wordsPerScreen, extraPausaAfterParagraph } = settings;

  let paragraphIndex = 0;
  let sentenceIndex = 0;
  let wordIndex = 0;

  let numberOfWords = 0;

  const chunks: IChunk[] = [];

  while (paragraphIndex < paragraphs.length) {
    const sentences = paragraphs[paragraphIndex];
    const words = sentences[sentenceIndex];
    let wordsChunk = words.slice(wordIndex, wordIndex + wordsPerScreen);

    wordIndex += wordsPerScreen;
    let extraDelay = 0;

    if (wordIndex >= words.length) {
      // end of words chunk in a sentence
      sentenceIndex += 1;
      wordIndex = 0;
    }

    if (sentenceIndex === sentences.length) {
      // end of sentences in a paragrah
      paragraphIndex += 1;
      sentenceIndex = 0;

      if (extraPausaAfterParagraph) {
        extraDelay = EXTRA_PAUSE_AFTER_PARAGRAPH_PERCENT;
      }
    }

    numberOfWords = numberOfWords + wordsChunk.length;

    wordsChunk = applyCommonSetting(wordsChunk, settings);

    chunks.push({
      html: wordsChunk.map(word => `${WORD_WRAPPER_START}${word}${WORD_WRAPPER_END}`).join(' '),
      wordsRead: numberOfWords,
      extraDelay,
    });

  }

  return { chunks, numberOfWords }
}

export const createRenderState = (settings: ISettings): IRenderState => {
  if (settings.mode === "dynamic") {
    const textStructure = prepareDynamicTextStructure(settings);
    const { chunks, numberOfWords } = prepareChunks(textStructure, settings);
    const chunksPerMinute = settings.wordsPerMinute / settings.wordsPerScreen;
    const tempo = Math.floor((60 * 1000) / chunksPerMinute)

    return {
      mode: {
        type: "dynamic",
        chunks,
        tempo,
        numberOfWords
      },
    }
  }

  if (settings.mode === "static") {
    const { html, numberOfWords } = prepareStaticHtml(settings)
    return {
      mode: {
        type: "static",
        html,
        numberOfWords,
      },
    }
  }

  throw new Error('Not supported mode');
}

export const createState = (defaultBoardSettings: ISettings, localStorageBoardSettings?: ISettings) => {
  try {
    const settings = localStorageBoardSettings || defaultBoardSettings;
    return {
      state: createRenderState(settings),
      settings
    }
  } catch {
    return {
      state: createRenderState(defaultBoardSettings),
      settings: defaultBoardSettings
    }
  }
}
