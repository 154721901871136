import { ThemeUICSSObject } from "theme-ui";

import { TModeColor } from "theme";

const placeholderKey = "&::placeholder";
const focusActiveKey = "&:focus, &:active:not([disabled])";

export const numberInputStyles: ThemeUICSSObject = {
  width: "100%",
  borderRadius: "lg",
  borderWidth: "1px",
  borderStyle: "solid",
  padding: "xs",
  fontSize: "12px",
  fontFamily: "body",
  resize: "vertical",

  // "&:hover": {
  //   borderColor: "n50",
  // },

  // '&[aria-disabled="true"]': {
  //   borderColor: "n10",
  //   backgroundColor: "n5",
  //   color: "n10",
  // },

  [focusActiveKey]: {
    outline: "transparent",
  },

  // '&[data-invalid="true"]': {
  //   borderColor: "r600",
  // },
};

export const colorModeStyles: Record<TModeColor, {}> = {
  default: {
    color: "n90",
    borderColor: "n20",
    backgroundColor: "n0",

    [placeholderKey]: {
      color: "n20",
    },

    [focusActiveKey]: {
      borderColor: "n50",
    }
  },
  dark: {
    color: "n0",
    backgroundColor: "n90",
    borderColor: "n60",

    [placeholderKey]: {
      color: "n50",
    },

    [focusActiveKey]: {
      borderColor: "n50",
    }
  },
};
