/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, Flex } from "theme-ui";
import { useBreakpointIndex } from "@theme-ui/match-media";
import React, { useCallback, useContext } from "react";
import { useTranslation } from "react-i18next";
import {
  DialogDisclosure,
  DialogStateReturn,
  useDialogState,
} from "reakit/Dialog";

import { PageContainer } from "components/Page";
import { BookIcon, EnvelopIcon, MenuIcon } from "components/Icons"; // LogoSensus
import { ModalHeader, ModalContent } from "components/Dialogs/Modal";

import { PATHS } from "views/paths";
import { LangContext } from "views/components/Locales";

import { NavItem } from "./NavItem";

import { useColorMode, TModeColor } from "theme";
import { RadioButtonGroup, RadioButton, Size as RadioGroupSize } from "components/RadioButton";
import { Button } from "components/Button";
import { Modal } from "components/Dialogs/Modal";
import { TLang } from "locales";
import { FormGroup, FormLabel } from "components/Form";

interface Props {}

const BookIconStyles = { fontSize: "16px" };
const EnvelopIconStyles = {};

interface LangRadioGroupProps {
  value: TLang;
  updateValue: (v: TLang) => void;
  size?: RadioGroupSize;
}

const LangRadioGroup: React.FC<LangRadioGroupProps> = ({ value, updateValue, size }) => {
  return (
    <RadioButtonGroup size={size}>
      <RadioButton
        label={"pl"}
        name="language"
        value="pl"
        checked={value === "pl"}
        onChange={(v) => updateValue(v as any)}
      />
      <RadioButton
        label={"en"}
        name="language"
        value="en"
        checked={value === "en"}
        onChange={(v) => updateValue(v as any)}
      />
    </RadioButtonGroup>
  );
};

interface ThemeRadioGroupProps {
  value: TModeColor;
  updateValue: (v: TModeColor) => void;
  size?: RadioGroupSize;
}

const ThemeRadioGroup: React.FC<ThemeRadioGroupProps> = ({ value, updateValue, size }) => {
  const { t } = useTranslation("exercises");

  return (
    <RadioButtonGroup size={size}>
      <RadioButton
        label={t("PAGE_TOPBAR.COLOR_MODE.DEFAULT")}
        name="colorMode"
        value="default"
        checked={value === "default"}
        onChange={(v) => updateValue(v as any)}
      />
      <RadioButton
        label={t("PAGE_TOPBAR.COLOR_MODE.DARK")}
        name="colorMode"
        value="dark"
        checked={value === "dark"}
        onChange={(v) => updateValue(v as any)}
      />
    </RadioButtonGroup>
  );
};

interface AppSettingsModalProps {
  dialogState: DialogStateReturn;
  onCloseClick: () => void;
}

const AppSettingsModal: React.FC<AppSettingsModalProps> = ({
  dialogState,
  onCloseClick,
}) => {
  const { t } = useTranslation("exercises");
  const { lang, changeLang } = useContext(LangContext);
  const [colorMode, setColorMode] = useColorMode();

  return (
    <Modal aria-label={t("APP_SETTINGS_MODAL.ARIA_LABEL")} {...dialogState}>
      <ModalHeader
        onCloseClick={onCloseClick}
        closeButtonAriaLabel="close-button-aria"
        headerPlaceholder={<div />}
      />
      <ModalContent>
        <Flex sx={{ flexDirection: "column", gap: "lg", flexGrow: 1 }}>
          <FormGroup>
            <FormLabel>{t("APP_SETTINGS_MODAL.THEME")}</FormLabel>
            <ThemeRadioGroup value={colorMode} updateValue={setColorMode} />
          </FormGroup>
          <FormGroup>
            <FormLabel>{t("APP_SETTINGS_MODAL.LANGUAGE")}</FormLabel>
            <LangRadioGroup value={lang} updateValue={changeLang} />
          </FormGroup>
        </Flex>
      </ModalContent>
    </Modal>
  );
};

const navigationListStyles = {
  listStyle: "none",
  margin: 0,
  padding: 0,
};

const topbarStyles = {
  borderBottom: "1px solid",
};

const colorModeStyles: Record<TModeColor, {}> = {
  default: {
    borderColor: "n10",
  },
  dark: {
    borderColor: "n70",
  },
};

const useStyles = (props: Props) => {
  const [colorMode] = useColorMode();

  return useCallback(() => {
    return {
      ...topbarStyles,
      ...colorModeStyles[colorMode],
    };
  }, [colorMode]);
};

export const Topbar: React.FC<Props> = (props) => {
  const { lang, changeLang } = useContext(LangContext);
  const [colorMode, setColorMode] = useColorMode();
  const { t } = useTranslation("exercises");

  const breakPointIndex = useBreakpointIndex();
  const dialogState = useDialogState({ animated: true });

  const handleModalClose = () => {
    dialogState.hide();
  };

  const sx = useStyles(props);

  return (
    <div sx={sx}>
      <PageContainer as="nav" sx={{ display: "flex", px: "sm" }}>
        <Flex sx={{ justifyContent: "space-between", flexGrow: 1 }}>
          {/* <Flex>
            <NavLink to={PATHS.HOME.ROOT.toPath()} sx={{ fontSize: "40px", pr: "xs" }}>
              <LogoSensus />
            </NavLink> */}
          <ul sx={navigationListStyles}>
            <NavItem
              icon={<BookIcon style={BookIconStyles} />}
              to={PATHS.EXERCISES.ROOT.toPath()}
              label={t("PAGE_TOPBAR.EXERCISES_LINK")}
            />
            <NavItem
              icon={<EnvelopIcon style={EnvelopIconStyles} />}
              to={PATHS.CONTACT.ROOT.toPath()}
              label={t("PAGE_TOPBAR.CONTACT_LINK")}
            />
          </ul>
          <Flex sx={{ alignItems: "center", gap: "xs", flexShrink: 0 }}>
            {breakPointIndex === 0 && (
              <DialogDisclosure
                as={Button}
                data-action-button
                variant="ghost"
                size="sm"
                iconLeft={<MenuIcon />}
                {...dialogState}
              />
            )}
            {breakPointIndex > 0 && (
              <React.Fragment>
                <ThemeRadioGroup size="sm" value={colorMode} updateValue={setColorMode} />
                <LangRadioGroup size="sm" value={lang} updateValue={changeLang} />
              </React.Fragment>
            )}
          </Flex>
        </Flex>
        <AppSettingsModal
          dialogState={dialogState}
          onCloseClick={handleModalClose}
        />
      </PageContainer>
    </div>
  );
};
