import { IBoardSettings, ICell, IBoardRenderState } from "./types";

import { fisherYatesShuffle } from "utils/text";

import {
  colsMax,
  colsMin,
  rowsMax,
  rowsMin,
} from "./const";

const SEQ_LETTERS = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z'];
const SEQ_DIGITS = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'];

const getSeqOptions = ({ charsType, count, startFrom }: { charsType: 'DIGITS' | 'LETTERS', count?: number, startFrom?: number }) => {
  if (charsType === 'DIGITS') {
    return typeof count === 'undefined' ? SEQ_DIGITS.slice() : new Array(count).fill(0).map((_, i) => `${i + (startFrom || 0)}`);
  }

  if (charsType === 'LETTERS') {
    return typeof count === 'undefined' ? SEQ_LETTERS.slice() : count < SEQ_LETTERS.length ? SEQ_LETTERS.slice(0, count) : SEQ_LETTERS.slice();
  }

  throw new Error(`getSeqOptions - Bad char parameter - ${charsType}`);
}

// export const getUniqueArray = (fun: ({ numOfChars }: { numOfChars: number }) => string) => ({ numOfChars, size }: { numOfChars: number, size: number }) => {
//     const set = new Set<string>();
//     while (set.size !== size) {
//       set.add(fun({ numOfChars }))
//     }

//     return Array.from(set);
// }

// export const getRandomArray = (fun: ({ numOfChars }: { numOfChars: number }) => string) => ({ numOfChars, size }: { numOfChars: number, size: number }) => {
//   return new Array(size).fill(0).map(() => fun({ numOfChars }));
// }

// export const getRandomNumber = ({ numOfChars }: { numOfChars: number }): string => {
//   let number = '';

//   while (number.length < numOfChars) {
//     if (number === '0') {  // ommit numbers like 0, 01, 02, etc
//       number = SEQ_DIGITS[Math.floor(Math.random() * SEQ_DIGITS.length)];
//     }

//     number = number + SEQ_DIGITS[Math.floor(Math.random() * SEQ_DIGITS.length)];
//   }

//   return number;
// };

// export const getRandomWord = ({ numOfChars }: { numOfChars: number }): string => {
//   let word = '';

//   for (let i = 0; i < numOfChars; i++) {
//     word = word + SEQ_LETTERS[Math.floor(Math.random() * SEQ_LETTERS.length)];
//   }

//   return word;
// }

// export const getRandom = ({ charsType }: { charsType: 'DIGITS' | 'LETTERS' }) => ({ numOfChars }: { numOfChars: number }) => {
//   if (charsType === 'DIGITS') {
//     return getRandomNumber({ numOfChars });
//   }
//   if (charsType === 'LETTERS') {
//     return getRandomWord({ numOfChars });
//   }

//   throw new Error(`getRandom - Bad char parameter - ${charsType}`);
// }

export const createBoard = ({ charsArray, rows, cols }: { charsArray: string[], rows: number, cols: number }): IBoardRenderState  => {
  const board: ICell[][] = [];

  for (let i = 0; i < rows; i++) {
    for (let j = 0; j < cols; j++) {
      if (!Array.isArray(board[i])) {
        board[i] = [];
      }

      const index = i * cols + j;
      board[i][j] = { value: charsArray[index]  };
    }
  }

  return { board };
}

export const createRenderState = (boardSettings: IBoardSettings): IBoardRenderState => {
  const rows = boardSettings.rows > rowsMax ? rowsMax : boardSettings.rows < rowsMin ? rowsMin : boardSettings.rows;
  const cols = boardSettings.cols > colsMax ? colsMax : boardSettings.cols < colsMin ? colsMin : boardSettings.cols;

  const cells = rows * cols;
  const charsType = boardSettings.charsType;

  // const type = boardSettings.mode.type;
  // const unique = boardSettings.mode.type === 'RANDOM' && boardSettings.mode.unique;
  // const treshold = 0.4;

  // if (type === 'RANDOM') {
  //   const numOfChars = boardSettings.mode.numOfChars;
  //   const maxNum = Math.pow(getSeqOptions({ charsType }).length, boardSettings.mode.numOfChars);
  //   const minNum = Math.pow(getSeqOptions({ charsType }).length, boardSettings.mode.numOfChars - 1) - 1;
  //   const options = maxNum - minNum;
  //   const chance = cells / options;

  //   if (unique && chance > treshold) {
  //     const allOptionsSequential = getSeqOptions({ charsType, startFrom: minNum, count: maxNum });
  //     return createBoard({ charsArray: fisherYatesShuffle(allOptionsSequential, cells), boardSettings })
  //   }

  //   if (unique) {
  //     const charsArray = getUniqueArray(getRandom({ charsType }))({ numOfChars, size: cells });
  //     return createBoard({ charsArray, boardSettings });
  //   }

  //   const charsArray = getRandomArray(getRandom({ charsType }))({ numOfChars, size: cells });
  //   return createBoard({ charsArray, boardSettings })
  // }

  // if (type === 'SEQUENTIAL') {
    const options = getSeqOptions({ charsType, count: cells, startFrom: 1 })
    return createBoard({ charsArray: fisherYatesShuffle(options, cells), rows, cols })
  // }
};

export const createState = (defaultBoardSettings: IBoardSettings, localStorageBoardSettings?: IBoardSettings) => {
  try {
    const settings = localStorageBoardSettings || defaultBoardSettings;
    return {
      state: createRenderState(settings),
      settings
    }
  } catch {
    return {
      state: createRenderState(defaultBoardSettings),
      settings: defaultBoardSettings
    }
  }
}
