import { TFontSize, TFontType } from "./types";

export const containerStyles = {
  display: "flex",
  flexDirection: "column",
  flex: 1,
};

export const contentStyles = {
  ...containerStyles,
  paddingBottom: 0,
};

export const fontTypes: Record<TFontType, string> = {
  modern: `"Montserrat", sans-serif`,
  classic: `"Noto Serif", serif;`,
  dyslexic: "opendyslexic",
}

export const settingsFontSizes: Record<TFontSize, string> = {
  "xs": "10px",
  "sm": "14px",
  "md": "18px",
  "lg": "22px",
  "xl": "26px",
}

export const boardFontSizes: Record<TFontSize, string> = {
  "xs": "12px",
  "sm": "16px",
  "md": "20px",
  "lg": "24px",
  "xl": "28px",
}

export const boardLineHeight: Record<TFontSize, string> = {
  "xs": "22px",
  "sm": "26px",
  "md": "30px",
  "lg": "38px",
  "xl": "44px",
}
