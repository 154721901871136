/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, Grid } from "theme-ui";
import { useTranslation } from "react-i18next";

import { PageContainer, PageContent } from "components/Page";
import { BookIcon } from "components/Icons";
import { Label } from "components/Label";

import { PATHS } from "views/paths";

import { ExerciseAnchor } from "./ExerciseAnchor";

export const ExercisesPage = () => {
  const { t } = useTranslation("exercises");

  return (
    <PageContainer>
      <PageContent>
        <Label>{t("PAGE_EXERCISES.PAGE_HEADER")}</Label>
        <Grid
          sx={{
            gap: "sm",
            gridTemplateColumns: [
              "repeat(1, 1fr)",
              "repeat(2, 1fr)",
              "repeat(3, 1fr)",
            ],
          }}
        >
          <ExerciseAnchor
            to={PATHS.EXERCISES.SPREADER.ROOT.toPath()}
            icon={<BookIcon />}
            label={t("PAGE_EXERCISES.ANCHOR_LABEL_SPREADER")}
          />
          <ExerciseAnchor
            to={PATHS.EXERCISES.SCHULTZ_BOARDS.ROOT.toPath()}
            icon={<BookIcon />}
            label={t("PAGE_EXERCISES.ANCHOR_LABEL_SCHULTZ_BOARDS")}
          />
        </Grid>
      </PageContent>
    </PageContainer>
  );
};
