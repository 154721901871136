export const text =
`<p>Z Ameryki Południowej do Afryki przewędrowała roślina, z której wyrabia się przyjemny napój – kakao. O ziarnach kakaowych wspominał już Krzysztof Kolumb, ale nie wzbudziły w nim ani w jego załodze większego zainteresowania. Dopiero Hernan Cortes miał szczęście spróbować u Azteków wspaniałego aromatycznego napoju, zwanego xocoatl. Był on tak cenny, że podobno władca Azteków Montezuma pił go tylko w złotych kubkach, które po jednorazowym użyciu wyrzucano... Nic więc dziwnego, że ziarna kakaowe służyły w starożytnym Meksyku za pieniądze. Za 100 ziaren można było kupić niewolnika.</p>
<p>Cortes wywożąc z podbitego kraju złoto nie zapomniał o kakaowcu. Przywiózł do Hiszpanii trochę ziaren, a przy okazji założył na wyspach u zachodnich wybrzeży Afryki plantacje drzew kakaowych. Hiszpanie dodali do gorzkiego xocoatl cukru i zaczęli podawać napój na gorąco. Pijalnie czekolady zaczęły wkrótce powstawać w całej Europie. Natomiast pierwsza tabliczka czekolady pojawiła się wkrótce po tym, jak holenderski chemik Konrad van Houtten odkrył sposób wytłaczania z ziarna masła kakaowego i wyrobu kakao w proszku.</p>
<p>Zanim białe ziarno kakaowca stanie się brązową czekoladą, musi przejść wiele zabiegów. Niezbyt wysokie drzewa kakaowe mają bardzo charakterystyczne, wyrastające wprost z pnia lub gałęzi wydłużone owoce przypominające kształtem duże ogórki. Mają one 20 do 30 cm długości i dojrzewając przybierają kolor czerwony. Każdy owoc zawiera 20 do 50 białych ziaren. Wyłuskuje się je i poddaje fermentacji w tropikalnym upale. Nabierają wówczas koloru i smaku. Wysuszone brunatne ziarna trafiają do fabryk czekolady. Tu najpierw się je czyści i praży podobnie jak kawę, a następnie miele i prasuje. Rezultatem tych zabiegów technicznych jest gęsta ciemna masa, zwana likierem czekoladowym. Z tego „likieru” wyciska się pod wysokim ciśnieniem bursztynowy płyn – masło kakaowe. Pozostałą prawie suchą masę miele się i rozdrabnia na proszek – tak powstaje kakao w proszku.</p>
<p>Kiedy zmiesza się masło kakaowe ze zmielonym ziarnem, doda cukru i np. mleka skondensowanego to jest już blisko do tabliczki czekolady. Samo masło kakaowe służy do wyrobu białych czekolad. Jeśli do produkcji tabliczki czekolady użyje się zamiast masła kakaowego jakiegoś innego tłuszczu roślinnego, to otrzyma się produkt niepełnowartościowy, co znamy jako „wyrób czekoladopodobny” – niestety.</p>
<p>Hiszpanom udało się utrzymać w tajemnicy sposób produkcji napoju czekoladowego przez prawie sto lat, ale już na początku XVII w. znano metody wyrabiania czekolady w całej Europie. Jednak wiele tajemnic produkcyjnych strzegą wielkie fabryki także dzisiaj. W 1980 r. np. głośno było o „aferze czekoladowej” w szwajcarskim koncernie Suchard-Tobler. Pewien młody człowiek tam pracujący zrobił fotokopie kilku receptur i zaoferował je ambasadzie Arabii Saudyjskiej, gdzie popularność czekolady gwałtownie wzrasta. Dyrekcja fabryki zlekceważyła jednak incydent, uważając, że bez stuletnich doświadczeń nie da się wyprodukować dobrej czekolady, nawet dysponując szczegółowymi przepisami.</p>
<p>Kto produkuje najlepszą czekoladę na świecie? Zdania na ten temat są podzielone. W największej amerykańskiej firmie czekoladowej Hershey, założonej w 1903 r., przechowuje się masę czekoladową przez 72 godziny w konwiach i to podobno gwarantuje jej wyborny smak. W Europie za najlepszą uchodzi czekolada szwajcarska wytwarzana z mieszanki wielu gatunków ziarna kakaowego. W Hiszpanii używa się do produkcji wyłącznie ziarna brazylijskiego, więc czekolada hiszpańska ma trochę inny smak niż czekolada francuska, wyrabiana z ziarna afrykańskiego, przywożonego z byłych kolonii francuskich w Afryce. Być może najlepsza jest czekolada z małej fabryczki Charbonnel at Walker w Londynie, która reklamuje się bez fałszywej skromności sloganem: „Prawdopodobnie najlepsza czekolada na świecie”.</p>
<p>Jest natomiast pewne, że najdroższą, najbardziej luksusowo opakowaną czekoladę produkuje belgijska „Godiva”. Większość wytwarzanych tam czekoladek to produkcja ręczna. Pakowane są w pokryte jedwabiem lub aksamitem pudełka, a jeśli transport przeznaczony jest do ciepłych krajów, pudełka te są izotermiczne, co sprawia, że czekolada nie rozpływa się mimo upału.</p>
<p>Sklepy „Godivy” w Stanach Zjednoczonych prowadziły kiedyś ekstrawagancką sprzedaż swoich czekoladek dla milionerów. (Podaję to na odpowiedzialność amerykańskiego pisma „National Geographic”). Otóż kiedy zamawiało się w „Godivie” czekoladki z dostawą do domu, to przywoziła je „lady Godiva” – dziewczyna na białym koniu, wprawdzie nie naga jak historyczny oryginał, ale w cielistym, cienkim trykocie. Do ceny czekoladek (40 do 60 dolarów za kilogram) klient dopłacał 3000 dolarów za dostarczenie do domu.</p>
<p>Szwedzki botanik, sławny Karol Linneusz, klasyfikując rośliny nazwał kakaowiec Teobroma cacao, co znaczy „przysmak bogów kakao”. Aztekowie podobno uważali kakao za rodzaj lubczyka i – jak najnowsze badania wskazują – nie byli zbyt odlegli od prawdy. W czekoladzie uczeni wykryli bowiem ślady tej samej substancji, jaka pojawia się w organizmie człowieka... zakochanego. Próbowano także stosować czekoladę jako lekarstwo. Niektórzy ludzie uważają, że może ona obniżyć gorączkę, inni twierdzą, że jest dla zdrowia szkodliwa, powoduje próchnicę zębów, prowadzi do otyłości, zaburza trawienie itp. Radykalne zdanie ma na ten temat pewien angielski lekarz: „czekolada jest zbyt dobra, by mogła być lekarstwem”.</p>`;
