/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from "theme-ui";
import { forwardRef } from "react";

type LabelProps = JSX.IntrinsicElements["label"];
export interface FormLabelProps extends LabelProps {}

export const FormLabel = forwardRef<HTMLLabelElement, FormLabelProps>(({ children, ...rest }, ref) => {
  return <label sx={{ fontSize: "12px", fontWeight: "bold" }} {...rest} ref={ref}>{children}</label>
});
