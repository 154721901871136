/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from "theme-ui";
import React, { useCallback } from "react";

import { TTheme, useColorMode } from "theme";
import {
  tabButtonStyles,
  placeholderWrapperStyles,
  contentWrapperStyles,
} from "./config";
import { forwardRefWithAs, PropsWithAs } from "theme/utils";

export type TabButtonVariant = keyof TTheme["tabButton"]["variants"];

export interface TabButtonComponentProps {
  variant?: TabButtonVariant;

  active?: boolean;
  disabled?: boolean;
  focusable?: boolean;
  loading?: boolean;

  placeholderLeft?: React.ReactNode;
  placeholderRight?: React.ReactNode;
}

const useTabButtonStyles = (props: TabButtonComponentProps) => {
  const { variant = "primary" } = props;
  const [colorMode] = useColorMode();

  return useCallback(
    (theme: TTheme) => {
      return {
        ...tabButtonStyles,
        variant: `tabButton.variants.${variant}.${colorMode}`,
      };
    },
    [variant, colorMode]
  );
};

export const TabButtonComponent = (
  props: PropsWithAs<TabButtonComponentProps, "button">,
  forwardedRef: React.Ref<HTMLButtonElement>
) => {
  const {
    as: Component = "button",
    active,
    children,
    variant,
    disabled,
    focusable = true,
    loading,
    placeholderLeft,
    placeholderRight,
    className,
    ...rest
  } = props;

  const sxFun = useTabButtonStyles(props);

  return (
    <Component
      sx={sxFun}
      ref={forwardedRef}
      aria-disabled={Boolean(disabled)}
      disabled={!focusable && disabled}
      role="tab"
      aria-selected={Boolean(active)}
      className={className}
      {...rest}
    >
      <span data-content-wrapper sx={contentWrapperStyles}>
        {placeholderLeft && (
          <span data-placeholder-wrapper-left sx={placeholderWrapperStyles}>
            {placeholderLeft}
          </span>
        )}
        {children && <span data-children>{children}</span>}
        {placeholderRight && (
          <span data-placeholder-wrapper-right sx={placeholderWrapperStyles}>
            {placeholderRight}
          </span>
        )}
      </span>
    </Component>
  );
};

export type TabButtonProps = PropsWithAs<TabButtonComponentProps, "button">;
export const TabButton = forwardRefWithAs<TabButtonComponentProps, "button">(
  TabButtonComponent
);
