/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from "theme-ui";
import React, { ChangeEvent, forwardRef, useCallback } from "react";
import merge from "lodash.merge";

import { useColorMode, TModeColor } from "theme";

type InputProps = JSX.IntrinsicElements["input"];
type ValueType = string | number | undefined;

export interface RadioButtonProps extends Omit<InputProps, "onChange"> {
  focusable?: boolean;
  invalid?: boolean;
  onChange?: (value: ValueType, event: ChangeEvent<HTMLInputElement>) => void;
  label?: React.ReactNode;
}

const checkedKey = "input:checked + label, input:hover + label";

const styles = {
  display: "inline-block",
  position: "relative",
  flex: 1,

  input: {
    opacity: 0.01,
    position: "absolute",
    inset: 0,
    cursor: "pointer",
  },

  label: {
    cursor: "pointer",
    display: "flex",
    paddingX: "xs",
    // paddingY: "xxs", defined in the parent component
    justifyContent: "center",
    borderRadius: "6px",
    transition: "200ms all",
    fontSize: "12px",
    whiteSpace: "nowrap",
  },
};

const colorModeStyles: Record<TModeColor, {}> = {
  default: {
    color: "n50",
    [checkedKey]: {
      color: "n90",
      backgroundColor: "n0",
      boxShadow: "0px 2px 4px rgb(28 27 31 / 12%)",
    }
  },
  dark: {
    color: "n20",
    [checkedKey]: {
      color: "n0",
      backgroundColor: "n90",
      boxShadow: "0px 2px 4px rgb(28 27 31 / 12%)",
    }
  },
};

const useStyles = (props: RadioButtonProps) => {
  const [colorMode] = useColorMode();

  return useCallback(() => {
    return merge(styles, colorModeStyles[colorMode]);
  }, [colorMode]);
};

export const RadioButton = forwardRef<HTMLInputElement, RadioButtonProps>(
  function RadioButton(props, ref) {
    const {
      focusable = true,
      invalid = false,
      disabled = false,
      checked = false,
      className,
      onChange,
      label,
      ...rest
    } = props;
    const styles = useStyles(props);
    const handleOnChange = useCallback(
      (event: ChangeEvent<HTMLInputElement>) => {
        if (!disabled) {
          onChange?.(event.target.value, event);
        }
      },
      [onChange, disabled]
    );

    const id = props.id ? props.id : ["r", props.name, props.value].join("-");

    return (
      <li sx={styles} className={className}>
        <input
          id={id}
          ref={ref}
          type="radio"
          checked={checked}
          data-radio-input
          data-testid="radio-input"
          aria-checked={checked}
          disabled={!focusable && disabled}
          aria-disabled={disabled}
          data-invalid={invalid}
          onChange={handleOnChange}
          {...rest}
        />
        <label htmlFor={id} data-radio-label>{label}</label>
      </li>
    );
  }
);
