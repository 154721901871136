import { fisherYatesShuffle } from "./fisherYatesShuffle";

export const HIDDEN_CHARS_HTML_ELEM_START = '<span data-hidden-chars>';
export const HIDDEN_CHARS_HTML_ELEM_END = "</span>";

const wrapText = (text: string) => {
  return `${HIDDEN_CHARS_HTML_ELEM_START}${text}${HIDDEN_CHARS_HTML_ELEM_END}`;
};

export interface IHideTextConfig {
  hidePercent: number
}

export const hideLetters = (chars: string, config: IHideTextConfig): string => {
  const { hidePercent } = config;
  const wordLength = chars.length;
  const howManyLetters = Math.ceil(wordLength * hidePercent / 100);

  const seqentialPositions = new Array(wordLength).fill(0).map((_, i) => `${i}`);
  const hiddenIndexes = new Set(fisherYatesShuffle(seqentialPositions, howManyLetters).map(Number))

  const text = [];
  let alreadyOpenHtmlTag = false;

  for (let i = 0; i < chars.length; i++) {
    if (hiddenIndexes.has(i) && alreadyOpenHtmlTag === false) {
      text.push(HIDDEN_CHARS_HTML_ELEM_START);
      alreadyOpenHtmlTag = true;
    }

    if (!hiddenIndexes.has(i) && alreadyOpenHtmlTag === true) {
      text.push(HIDDEN_CHARS_HTML_ELEM_END);
      alreadyOpenHtmlTag = false;
    }

    text.push(chars[i]);
  }

  if (alreadyOpenHtmlTag) {
    text.push(HIDDEN_CHARS_HTML_ELEM_END);
  }

  return text.join('');
}

export const hideWords = (words: string[], config: { hidePercent: number }) => {
  const { hidePercent } = config;
  const wordsLength = words.length;
  const howManyWords = Math.ceil(wordsLength * hidePercent / 100);

  const seqentialPositions = new Array(wordsLength).fill(0).map((_, i) => `${i}`);
  const hiddenIndexes = new Set(fisherYatesShuffle(seqentialPositions, howManyWords).map(Number))

  const newWords = [];
  let i = 0;

  while (i < words.length) {
    if (hiddenIndexes.has(i)) {
      newWords.push(wrapText(words[i]));
    } else {
      newWords.push(words[i]);
    }

    i = i + 1;
  }

  return newWords;
}
