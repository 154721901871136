/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from "theme-ui";
import React, { PropsWithChildren } from "react";

export interface DialogContentProps {}

export const DialogContent = React.forwardRef<
  HTMLDivElement,
  PropsWithChildren<DialogContentProps>
>((props, ref) => {
  const { children, ...rest } = props;

  return (
    <div
      sx={{
        p: "sm",
        display: "flex",
        flex: 1,
      }}
      {...rest}
      ref={ref}
      data-component="dialog-content"
    >
      {children}
    </div>
  );
});
