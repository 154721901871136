export type TStorageProvider = typeof localStorage | typeof sessionStorage;

export const setItem = (key: string, data: any, storageProvider: TStorageProvider = localStorage) => {
  if (data) {
    storageProvider.setItem(key, JSON.stringify(data));
  } else {
    storageProvider.removeItem(key);
  }
};

export const getItem = <T>(key: string, storageProvider: TStorageProvider = localStorage): T | undefined => {
  const payload = storageProvider.getItem(key);
  if (payload) {
    try {
      return JSON.parse(payload);
    } catch (error) {
      return undefined;
    }
  }
  return undefined;
};

export const setSessionItem = (key: string, data: any) => setItem(key, data, sessionStorage);

export const getSessionItem = <T>(key: string) => getItem<T>(key, sessionStorage);
