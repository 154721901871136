/** @jsxRuntime classic */
/** @jsx jsx */
import React, { useCallback, useMemo } from "react";
import { jsx } from "theme-ui";
import merge from "lodash.merge";

import { numberInputStyles, colorModeStyles } from "./config";

import { useColorMode } from "theme";

type InputHTMLProps = Omit<
  JSX.IntrinsicElements["input"],
  "onChange" | "ref" | "value" | "size" | "type"
>;

export type NumberInputValue = number | string | null;

export interface NumberInputProps extends InputHTMLProps {
  value?: NumberInputValue;
  focusable?: boolean;
  invalid?: boolean;
  rows?: number;
  onChange?: (
    value: NumberInputValue,
    event?: React.ChangeEvent<HTMLInputElement>
  ) => void;
}

const useStyles = (props: NumberInputProps) => {
  const [colorMode] = useColorMode();

  return useCallback(() => {
    return merge(numberInputStyles, colorModeStyles[colorMode]);
  }, [colorMode]);
};

export const NumberInput = React.forwardRef<HTMLInputElement, NumberInputProps>(
  (props, forwardedRef) => {
    const {
      disabled,
      focusable = true,
      readOnly,
      value,
      onChange,
      invalid,
      ...inputProps
    } = props;
    const isReadOnly = (disabled && focusable) || readOnly;

    const handleChange = useCallback(
      (e: React.ChangeEvent<HTMLInputElement>) => {
        if (onChange && !isReadOnly) {
          onChange(e.target?.value || "", e);
        }
      },
      [onChange, isReadOnly]
    );

    const inputValue = useMemo(
      () => (value === null ? "" : `${value}`),
      [value]
    );

    const styles = useStyles(props);

    return (
      <input
        sx={styles}
        type="number"
        inputMode="numeric"
        pattern="[0-9]*"
        aria-disabled={Boolean(disabled)}
        disabled={!focusable && disabled}
        readOnly={Boolean(isReadOnly)}
        value={inputValue}
        onChange={handleChange}
        data-invalid={Boolean(invalid)}
        {...inputProps}
        ref={forwardedRef}
      />
    );
  }
);
