import { TModeColor, TTheme } from "theme";

const textStyles = (theme: TTheme) => ({
  fontSize: "12px !important",
  fontWeight: `${theme.fontWeights.normal} !important`,
  fontFamily: `${theme.fonts.body} !important`,
  fontStyle: `normal !important`,
  whiteSpace: "normal",
  cursor: "text !important",
});

type VariantProps = Record<string, string>

const makeVariant = ({ backgroundColor, color }: VariantProps) => ({
  backgroundColor: `${backgroundColor} !important`,
  color: `${color} !important`,
})

const placeholderKey = "&::placeholder";
const focusActiveKey = "&:focus, &:active:not([disabled])";

export const styles = (theme: TTheme) => ({
  ...textStyles(theme),

  "ul, ol": {
    m: "0 !impotant",
    padding: "0 !important",
    listStyle: "none !important",
  },

  width: "100%",

  borderRadius: "lg",
  borderWidth: "1px",
  borderStyle: "solid",
  padding: "xs",

  "*": {
    ...textStyles(theme),
  },

  [placeholderKey]: {
    color: "n20",
  },

  // "&:hover": {
  //   borderColor: "n50",
  // },

  // '&[aria-disabled="true"]': {
  //   borderColor: "n10",
  //   backgroundColor: "n5",
  //   color: "n10",
  // },

  [focusActiveKey]: {
    outline: "transparent",
  },

  // '&[data-invalid="true"]': {
  //   borderColor: "r600",
  // },
})

export const colorModeStyles = (theme: TTheme): Record<TModeColor, {}> => ({
  default: {
    ...makeVariant({ backgroundColor: theme.colors.n0, color: theme.colors.n90 }),

    "*": {
      ...makeVariant({ backgroundColor: theme.colors.n0, color: theme.colors.n90 }),
    },

    borderColor: "n20",

    [placeholderKey]: {
      color: "n20",
    },

    [focusActiveKey]: {
      borderColor: "n50",
    }
  },
  dark: {
    ...makeVariant({ backgroundColor: theme.colors?.n90, color: theme.colors?.n0 }),

    "*": {
      ...makeVariant({ backgroundColor: theme.colors?.n90, color: theme.colors?.n0 }),
    },

    borderColor: "n60",

    [placeholderKey]: {
      color: "n50",
    },

    [focusActiveKey]: {
      borderColor: "n50",
    }
  },
});
