/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, Flex } from "theme-ui";
import React from "react";
import { useTranslation } from "react-i18next";

import { Switch } from "components/Switch";
import { NumberInput } from "components/NumberInput";
import { FormLabel, FormGroup } from "components/Form";
import { Slider, SliderRangeLabel } from "components/Slider";
import { RadioButton, RadioButtonGroup } from "components/RadioButton";

import { ISettings } from "../types";
import { wordsPerMinuteMax, wordsPerMinuteMin, wordsPerMinuteStep } from "../const";

interface DynamicOnlySettingsProps {
  settings: ISettings;
  onChange: (settings: ISettings) => void;
}

export const DynamicOnlySettings: React.FC<DynamicOnlySettingsProps> = ({
  settings,
  onChange,
}) => {
  const { t } = useTranslation("exercises");

  const {
    wordsPerMinute,
    sentenceOnNewScreen,
    extraPausaAfterParagraph,
    textPosition,
  } = settings;


  const wordsSliderValue = Number(wordsPerMinute);

  const wordsPerScreen = String(settings.wordsPerScreen);

  const handleSettingChange = (name: string) => (value: any) => {
    const v = name === "wordsPerScreen" ? Number(value) : value;
    onChange({ ...settings, [name]: v });
  };

  return (
    <React.Fragment>
      <FormGroup>
        <FormLabel>
          {t("SPREADER.SETTINGS.MODAL_WORDS_PER_MINUTE_LABEL")}
        </FormLabel>
        <Flex sx={{ gap: "sm", alignItems: "center" }}>
          <NumberInput
            sx={{ flex: "0 0 16.66667%" }}
            value={wordsPerMinute}
            onChange={handleSettingChange("wordsPerMinute")}
            step={wordsPerMinuteStep}
            min={wordsPerMinuteMin}
            max={wordsPerMinuteMax}
          />
          <Flex sx={{ flexDirection: "column", flex: 1 }}>
            <Slider
              value={wordsSliderValue}
              onChange={handleSettingChange("wordsPerMinute")}
              step={wordsPerMinuteStep}
              min={wordsPerMinuteMin}
              max={wordsPerMinuteMax}
            />
            <Flex sx={{ justifyContent: "space-between" }}>
              <SliderRangeLabel>{wordsPerMinuteMin}</SliderRangeLabel>
              <SliderRangeLabel>{wordsPerMinuteMax}</SliderRangeLabel>
            </Flex>
          </Flex>
        </Flex>
      </FormGroup>

      <FormGroup>
        <FormLabel>
          {t("SPREADER.SETTINGS.MODAL_WORDS_ON_SCREEN_LABEL")}
        </FormLabel>
        <RadioButtonGroup>
          <RadioButton
            label={"1"}
            name="wordsPerScreen"
            value="1"
            checked={wordsPerScreen === "1"}
            onChange={handleSettingChange("wordsPerScreen")}
          />
          <RadioButton
            label={"2"}
            name="wordsPerScreen"
            value="2"
            checked={wordsPerScreen === "2"}
            onChange={handleSettingChange("wordsPerScreen")}
          />
          <RadioButton
            label={"3"}
            name="wordsPerScreen"
            value="3"
            checked={wordsPerScreen === "3"}
            onChange={handleSettingChange("wordsPerScreen")}
          />
          <RadioButton
            label={"4"}
            name="wordsPerScreen"
            value="4"
            checked={wordsPerScreen === "4"}
            onChange={handleSettingChange("wordsPerScreen")}
          />
          <RadioButton
            label={"5"}
            name="wordsPerScreen"
            value="5"
            checked={wordsPerScreen === "5"}
            onChange={handleSettingChange("wordsPerScreen")}
          />
          <RadioButton
            label={"6"}
            name="wordsPerScreen"
            value="6"
            checked={wordsPerScreen === "6"}
            onChange={handleSettingChange("wordsPerScreen")}
          />
        </RadioButtonGroup>
      </FormGroup>

      <Flex sx={{ justifyContent: "space-between", alignItems: "center" }}>
        <FormLabel htmlFor="switch-sentence-on-new-screen">
          {t("SPREADER.SETTINGS.MODAL_SENTENCE_ON_NEW_SCREEN_LABEL")}
        </FormLabel>
        <Switch
          id="switch-sentence-on-new-screen"
          selected={sentenceOnNewScreen}
          onChange={handleSettingChange("sentenceOnNewScreen")}
        />
      </Flex>

      <Flex sx={{ justifyContent: "space-between", alignItems: "center" }}>
        <FormLabel htmlFor="switch-pause-after-paragraph">
          {t("SPREADER.SETTINGS.MODAL_EXTRA_PAUSE_AFTER_PARAGRAPH_LABEL")}
        </FormLabel>
        <Switch
          id="switch-pause-after-paragraph"
          selected={extraPausaAfterParagraph}
          onChange={handleSettingChange("extraPausaAfterParagraph")}
        />
      </Flex>

      <FormGroup>
        <FormLabel>{t("SPREADER.SETTINGS.MODAL_TEXT_POSITION")}</FormLabel>

        <RadioButtonGroup>
          <RadioButton
            label={t("SPREADER.SETTINGS.MODAL_TEXT_POSITION_CENTER")}
            name="textPosition"
            value="center"
            checked={textPosition === "center"}
            onChange={handleSettingChange("textPosition")}
          />
          <RadioButton
            label={t("SPREADER.SETTINGS.MODAL_TEXT_POSITION_RANDOM")}
            name="textPosition"
            value="random"
            checked={textPosition === "random"}
            onChange={handleSettingChange("textPosition")}
          />
        </RadioButtonGroup>
      </FormGroup>
    </React.Fragment>
  );
};
