/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from "theme-ui";
import React, { useEffect, useLayoutEffect, useRef, useCallback } from "react";

import { IStaticRenderState, ISettings, TActionState } from "../types";
import { BoardContainer } from "./BoardContainer";
import { BoardInnerContainer } from "./BoardInnerContainer";

import { useInnerBoardContainerStyles } from "./useInnerBoardContainerStyles";

interface Props {
  index: number;
  onIndexChange: (index: number) => void;
  onIndexMaxChange: (index: number) => void;
  renderState: IStaticRenderState;
  actionState: TActionState;
  settings: ISettings;
}

interface Point {
  x: number;
  y: number;
}

export const StaticPunctualPointerBoard: React.FC<Props> = React.memo(
  ({
    renderState,
    actionState,
    index,
    onIndexChange,
    settings,
    onIndexMaxChange,
  }) => {
    const { punctualPointsAmount, columnWidthPercent } = settings;

    const pointerRef = useRef<HTMLDivElement>(null);
    const innerContainerRef = useRef<HTMLDivElement>(null);

    const punctualPointsAmountRef = useRef(punctualPointsAmount);
    punctualPointsAmountRef.current = punctualPointsAmount;

    const html = renderState.html;

    const actionStateRef = useRef(actionState);

    const timeoutRef = useRef<NodeJS.Timeout>();

    const indexRef = useRef(index);
    indexRef.current = index;

    const pointsRef = useRef<Point[]>([]);

    const getTempo = useCallback(() => {
      return (renderState.numberOfWords * 60 * 1000) / settings.wordsPerMinute;
    }, [renderState.numberOfWords, settings.wordsPerMinute]);

    const transformPointer = useCallback(({ i }: { i: number }) => {
      requestAnimationFrame(() => {
        if (
          pointerRef.current &&
          pointsRef.current.length &&
          pointsRef.current[i]
        ) {
          const point = pointsRef.current[i];
          pointerRef.current.style.transform = `translate3d(${point.x}px, ${point.y}px, 0)`;
        }
      });
    }, []);

    const setupPointer = useCallback(
      ({ width }: { width: number; height: number }) => {
        pointsRef.current = [];

        const words: HTMLElement[] = Array.from(
          document.querySelectorAll("[data-word]")
        );

        if (!words.length) {
          return;
        }

        const pointerYOffset =
          ((pointerRef.current?.offsetHeight || 0) / 2) * -1;
        const pointerXOffset =
          ((pointerRef.current?.offsetWidth || 0) / 2) * -1;

        const firstWord = words[0];

        let lastY = firstWord.offsetTop + firstWord.offsetHeight;
        let lastWord = firstWord;

        const containerBoxWidth = width / punctualPointsAmountRef.current;

        const resolvePointsInLine = ({
          x,
          y,
          containerBoxWidth,
          offsetX,
          offsetY,
        }: {
          x: number;
          y: number;
          containerBoxWidth: number;
          offsetX: number;
          offsetY: number;
        }) => {
          const linePoints = [];

          for (let i = punctualPointsAmountRef.current - 1; i >= 0; i--) {
            if (x > i * containerBoxWidth) {
              for (let j = 0; j < i + 1; j++) {
                linePoints.push({
                  x: j * containerBoxWidth + containerBoxWidth / 2 + offsetX,
                  y: y + offsetY,
                });
              }
              break;
            }
          }
          return linePoints;
        };

        const wordRightX = (element: HTMLElement) =>
          element.offsetLeft + element.offsetWidth;

        for (let i = 1; i < words.length; i++) {
          const word = words[i];
          const y = word.offsetTop + word.offsetHeight;

          if (y !== lastY) {
            // the word in a new line
            const lastWordX = wordRightX(lastWord);
            pointsRef.current.push(
              ...resolvePointsInLine({
                x: lastWordX,
                y: lastY,
                containerBoxWidth,
                offsetX: pointerXOffset,
                offsetY: pointerYOffset,
              })
            );

            lastY = y;
          }

          lastWord = word;
        }

        pointsRef.current.push(
          ...resolvePointsInLine({
            x: wordRightX(lastWord),
            y: lastY,
            containerBoxWidth,
            offsetX: pointerXOffset,
            offsetY: pointerYOffset,
          })
        );

        onIndexMaxChange(Math.max(pointsRef.current.length - 1, 0));
        transformPointer({ i: indexRef.current });

        // / ----- debug code -----
        // const pointerLines: HTMLElement[] = Array.from(
        //   document.querySelectorAll("[data-pointer-line]")
        // );

        // pointerLines.forEach((pointerLine) => {
        //   pointerLine.remove();
        // });

        // const containerBoxWidth2 = width / punctualPointsAmountRef.current;

        // for (let i = 1; i <= punctualPointsAmountRef.current; i++) {
        //   const node = document.createElement("div");
        //   node.style.transform = `translate3d(${i * containerBoxWidth2 - containerBoxWidth2 / 2}px, 0px, 0)`;
        //   node.style.position = "absolute";
        //   node.style.width = "1px";
        //   node.style.top = "0px";
        //   node.style.height = "500px";
        //   node.style.background = "blue";

        //   node.setAttribute("data-pointer-line", "true");

        //   innerContainerRef.current?.appendChild(node);
        // }

        // const node = document.createElement("div");
        // node.style.transform = `translate3d(${0 * containerBoxWidth2}px, 0px, 0)`;
        // node.style.position = "absolute";
        // node.style.width = "1px";
        // node.style.top = "0px";
        // node.style.height = "500px";
        // node.style.width = `${containerBoxWidth2}px`;
        // node.style.opacity = "0.2";
        // node.style.background = "yellow";

        // node.setAttribute("data-pointer-line", "true");

        // innerContainerRef.current?.appendChild(node);

        // const node2 = document.createElement("div");
        // node2.style.transform = `translate3d(${1 * containerBoxWidth2}px, 0px, 0)`;
        // node2.style.position = "absolute";
        // node2.style.width = "1px";
        // node2.style.top = "0px";
        // node2.style.height = "500px";
        // node2.style.width = `${containerBoxWidth2}px`;
        // node2.style.opacity = "0.2";
        // node2.style.background = "green";

        // node2.setAttribute("data-pointer-line", "true");

        // innerContainerRef.current?.appendChild(node2);

        // const node3 = document.createElement("div");
        // node3.style.transform = `translate3d(${2 * containerBoxWidth2}px, 0px, 0)`;
        // node3.style.position = "absolute";
        // node3.style.width = "1px";
        // node3.style.top = "0px";
        // node3.style.height = "500px";
        // node3.style.width = `${containerBoxWidth2}px`;
        // node3.style.opacity = "0.2";
        // node3.style.background = "red";

        // node3.setAttribute("data-pointer-line", "true");

        // innerContainerRef.current?.appendChild(node3);

        // Array.from(
        //   document.querySelectorAll("[data-pointer-test]")
        // ).forEach(pointer => pointer.remove());

        // pointsRef.current.forEach(point => {
        //   const node = document.createElement("div");
        //   node.setAttribute('data-pointer-test', 'true');
        //   node.setAttribute('data-pointer', 'true');

        //   node.style.transform = `translate3d(${point.x}px, ${point.y}px, 0)`;
        //   node.style.background = 'red';

        //   innerContainerRef.current?.appendChild(node);
        // });
      },
      [onIndexMaxChange, transformPointer]
    );

    const innerContainerObserver = useRef(
      new ResizeObserver((entries) => {
        for (let entry of entries) {
          const cr = entry.contentRect;
          requestAnimationFrame(() => {
            setupPointer({ width: cr.width, height: cr.height });
          });
        }
      })
    );

    useLayoutEffect(() => {
      if (!innerContainerRef.current) {
        return;
      }

      const observer = innerContainerObserver.current;
      const innerContainer = innerContainerRef.current;
      observer.observe(innerContainer);

      return () => {
        observer.unobserve(innerContainer);
      };
    }, [renderState.numberOfWords, punctualPointsAmount, columnWidthPercent]);

    useEffect(() => {
      if (actionStateRef.current === actionState) {
        return;
      }

      actionStateRef.current = actionState;

      const pause = () => {
        if (timeoutRef.current) {
          clearTimeout(timeoutRef.current);
        }
      };

      const run = () => {
        if (indexRef.current < pointsRef.current.length) {
          onIndexChange(indexRef.current + 1);

          const tempo = getTempo() / pointsRef.current.length;
          timeoutRef.current = setTimeout(run, tempo);
        }
      };

      if (actionState === "PLAY") {
        run();
      } else if (actionState === "PAUSE") {
        pause();
      }

      return () => {
        pause();
      };
    }, [actionState, renderState, onIndexChange, getTempo]);

    useEffect(() => {
      transformPointer({ i: index });
    }, [index, transformPointer]);

    const boardInneContainerSx = useInnerBoardContainerStyles({
      columnWidthPercent,
      mode: "static",
    });

    return (
      <BoardContainer settings={settings} mode={renderState.type}>
        <BoardInnerContainer ref={innerContainerRef} sx={boardInneContainerSx}>
          <div data-text dangerouslySetInnerHTML={{ __html: `${html}` }} />
          {Boolean(renderState.numberOfWords) && (
            <div data-pointer ref={pointerRef} />
          )}
        </BoardInnerContainer>
      </BoardContainer>
    );
  }
);
