/** @jsxRuntime classic */
/** @jsx jsx */
import React, { PropsWithChildren } from "react";
import { jsx } from "theme-ui";
import {
  Dialog as ReakitDialog,
  DialogBackdrop as ReakitDialogBackdrop,
} from "reakit/Dialog";

export interface DialogBackdropProps
  extends React.ComponentPropsWithoutRef<typeof ReakitDialog> {}

export const DialogBackdrop = React.forwardRef<
  HTMLDivElement,
  PropsWithChildren<DialogBackdropProps>
>((props, ref) => {
  return (
    <ReakitDialogBackdrop
      data-component="backdrop"
      sx={{
        position: "fixed",
        inset: 0,
        overflow: "auto",
        zIndex: "modal",
        bg: "rgba(0,0,0,0.4)",
        transition: "opacity 250ms ease-in-out",
        opacity: 0,

        "&[data-enter]": {
          opacity: 1,
        },
      }}
      {...props}
      ref={ref}
    >
      {props.children}
    </ReakitDialogBackdrop>
  );
});
