import { ThemeUICSSObject } from "theme-ui";

import { TColor } from "theme/colors";
import { TModeColor } from "theme";

type VariantProps = Record<string, TColor | "transparent">

const makeVariant = ({
  color,
  selectedColor,
  hoverColor,
  activeColor
}: VariantProps) => ({
    backgroundColor: "transparent",
    color,

    '&[aria-selected="true"]': {
      color: selectedColor,

      "&:after": {
        backgroundColor: "yellow",
        transform: "scaleX(1)",
      },
    },
    '&:not([disabled]), &:not([aria-disabled="true"])': {
      "&:hover": {
        color: hoverColor,
      },

      '&:active, &[data-active="true"]': {
        color: activeColor,
      },
    },
    // '&[disabled], &[aria-disabled="true"]': {
    //   color: "n10",
    //   cursor: "default",
    //   pointerEvents: "none",
    // },
})

type MakeVariants = Record<TModeColor, VariantProps>

const makeVariants = (props: MakeVariants) => {
  const variants: Record<TModeColor, {}> = {
    default: {},
    dark: {},
  };
  Object.keys(props).forEach(mode => {
    variants[mode as TModeColor] = makeVariant(props[mode as TModeColor]);
  });

  return variants;
};

export const tabButton = {
  variants: {
    primary: makeVariants({
      default: {
        color: "n50",
        selectedColor: "n90",
        hoverColor: "n90",
        activeColor: "n90",
      },
      dark: {
        color: "n20",
        selectedColor: "n0",
        hoverColor: "n0",
        activeColor: "n0",
      }
    })
  }
};

export const contentWrapperStyles: ThemeUICSSObject = {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  height: "100%",
  flexWrap: "nowrap",

  fontSize: "14px",
  fontWeight: "bolder",

  "[data-placeholder-wrapper-left] + [data-children], [data-children] + [data-placeholder-wrapper-right]":
    {
      ml: "6px",
    },
};

export const placeholderWrapperStyles: ThemeUICSSObject = {
  display: "flex",
};

export const tabButtonStyles: ThemeUICSSObject = {
  cursor: "pointer",
  position: "relative",
  height: "40px",
  px: "sm",
  border: 0,
  transition: "200ms background-color",
  outlineOffset: "1px",
  overflow: "hidden",
  display: "inline-block",
  textDecoration: "none",
  flexShrink: 0,
  appearance: "none",
  font: "inherit",
  "&:focus": {
    zIndex: 1,
  },
  "&:after": {
    content: "''",
    display: "block",
    height: "3px",
    width: "100%",
    position: "absolute",
    bottom: 0,
    left: 0,
    right: 0,
    transform: "scaleX(0)",
    transition: "200ms transform",
  },

  "& + &": {
    ml: ["-8px", 0, 0],
  }
};
