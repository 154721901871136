/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from "theme-ui";
import React, {
  ComponentType,
  forwardRef,
  SVGAttributes,
  SVGProps,
  useCallback,
} from "react";

import { TTheme } from "theme";
import { getResponsiveStyles, TResponsiveValue, memoize } from "theme/utils";
import { baseIconStyles } from "./config";

export type IconSize = keyof TTheme["icons"]["sizes"];
export type IconColor = keyof TTheme["colors"];
export type IconDisplay = "inline" | "block" | "none";

export interface ISvgIconProps extends SVGAttributes<SVGSVGElement> {
  size?: IconSize;
  color?: IconColor;
  display?: IconDisplay;
}

export interface ISvgIconInternalProps extends ISvgIconProps {
  Svg: React.ComponentType<SVGProps<SVGSVGElement>>;
}

const getKey = (
  _theme: TTheme["icons"]["sizes"],
  size: IconSize | TResponsiveValue<IconSize>
) => {
  return Array.isArray(size) ? size.join("-") : size;
};

const getSize = memoize(getResponsiveStyles, getKey);

const useStyles = (props: ISvgIconProps) => {
  const { size, color } = props;

  return useCallback(
    (theme: TTheme) => ({
      ...baseIconStyles,
      color,
      ...(size ? getSize(theme.icons.sizes, size) : undefined),
    }),
    [size, color]
  );
};

export const SvgIcon = forwardRef<SVGSVGElement, ISvgIconInternalProps>(
  (props, ref) => {
    const { Svg, ...restProps } = props;
    const sxFun = useStyles(restProps);

    return (
      <Svg
        focusable="false"
        aria-hidden="true"
        data-icon
        sx={sxFun}
        {...restProps}
        ref={ref}
      />
    );
  }
);

export type TSvgIconProps = React.ComponentPropsWithRef<typeof SvgIcon>;

export const createIcon = (Svg: ComponentType<SVGProps<SVGSVGElement>>) => {
  return forwardRef<SVGSVGElement, ISvgIconProps>((props, ref) => (
    <SvgIcon Svg={Svg} {...props} ref={ref} />
  ));
};
