import { useEffect, useRef } from 'react';
import ReactGA from 'react-ga';
import { useLocation, Location } from "react-router-dom";

const GA_KEY = process.env.REACT_APP_GOOGLE_ANALYTICS_KEY;

const logPageView = (location: Location) => {
  ReactGA.set({ page: location.pathname + location.search });
  ReactGA.pageview(location.pathname + location.search);
};

export const useGA = () => {
  const isInitializedRef = useRef(false);
  const location = useLocation();

  useEffect(() => {
    if (!GA_KEY) {
      return;
    }

    if (!isInitializedRef.current) {
      ReactGA.initialize(GA_KEY, { debug: false });
      isInitializedRef.current = true;
    }

    if (isInitializedRef.current) {
      logPageView(location);
    }
  }, [location])
};

export const sendGAEvent = (params: ReactGA.EventArgs) => {
  return ReactGA.event(params);
};
