const ROOT_PATH = "/exercises";

export const ROOT = {
  PATH: ROOT_PATH,
  toPath: () => ROOT_PATH,
};

const SCHULTZ_BOARDS_ROOT_PATH = `${ROOT_PATH}/schultz-boards`;
export const SCHULTZ_BOARDS = {
  ROOT: {
    PATH: SCHULTZ_BOARDS_ROOT_PATH,
    toPath: () => SCHULTZ_BOARDS_ROOT_PATH,
  }
}

const SPREADER_ROOT_PATH = `${ROOT_PATH}/spreader`;
export const SPREADER = {
  ROOT: {
    PATH: SPREADER_ROOT_PATH,
    toPath: () => SPREADER_ROOT_PATH,
  }
}
