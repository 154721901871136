/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from "theme-ui";
import { PageContainer, PageContent } from "components/Page";
import { useState } from "react";
import { useDialogState } from "reakit/Dialog";

import { setItem, getItem } from "utils";

import { Board } from "./Board";
import { Bar } from "./Bar";

import { createState, createRenderState } from "./service";
import { IBoardSettings, IBoardRenderState } from "./types";

const defaultSettings: IBoardSettings = {
  rows: 3,
  cols: 3,
  charsType: "DIGITS",
};

const storageKey = "shultzboard-settings";
const { state: spreaderState, settings: spreaderSettings } = createState(
  defaultSettings,
  getItem(storageKey)
);

export const SchultzBoardsPage = () => {
  const [settings, setSettings] = useState<IBoardSettings>(spreaderSettings);
  const [renderState, setRenderState] =
    useState<IBoardRenderState>(spreaderState);

  const dialogState = useDialogState({ animated: true });

  const handleSettingsChange = (newSettings: IBoardSettings) => {
    const newRenderState = createRenderState(newSettings);
    setItem(storageKey, newSettings);
    setSettings(newSettings);
    setRenderState(newRenderState);
  };

  const handleRefreshClick = () => {
    handleSettingsChange(settings);
  };

  return (
    <PageContainer>
      <PageContent>
        <div
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "sm",
            width: ["100%", "70%", "40%"],
            margin: "0 auto",
          }}
        >
          <Board settings={settings} renderState={renderState} />
          <Bar
            settings={settings}
            defaultSettings={defaultSettings}
            onSettingsChange={handleSettingsChange}
            onRefreshClick={handleRefreshClick}
            dialogState={dialogState}
          />
        </div>
      </PageContent>
    </PageContainer>
  );
};
