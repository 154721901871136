import { getItem } from "utils";

import { button } from "components/Button/config";
import { icons } from "components/Icons/config";
import { typography } from "components/Typography/config";
import { status } from "components/Status/config";
import { tabButton } from "components/TabButton/config";

import { colors } from "./colors";
import { breakpoints } from "./viewports";

export const COLOR_MODE_STORAGE_KEY = "color-mode";
export type TModeColor = 'default' | 'dark';

const storedColorMode: TModeColor | string = getItem(COLOR_MODE_STORAGE_KEY) || '';
const initialColorModeName = ['default', 'dark'].indexOf(storedColorMode) !== -1 ? storedColorMode : 'default';

export const theme = {
  initialColorModeName,
  useLocalStorage: false,

  breakpoints,

  space: { xxxs: 2, xxs: 4, xs: 8, sm: 16, md: 24, lg: 32, xl: 48, xxl: 64 },

  radii: {
    md: "4px",
    lg: "8px",
    xl: "12px",
  },

  zIndices: {
    modal: 1050,
  },

  colors,
  ...typography,

  button,
  icons,
  status,
  tabButton,

  styles: {
    root: {
      fontFamily: "body",
      fontWeight: "normal",
      fontSize: "14px",
      lineHeight: "20px",
      color: "bodyColor",
      backgroundColor: "bodyBg",
      webkitFontSmoothing: "antialiased",
    },
  },
};
