/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from "theme-ui";
import React, { useCallback } from "react";

import { TTheme, useColorMode } from "theme";
import {
  getResponsiveStyles,
  memoize,
  TResponsiveValue,
  forwardRefWithAs,
  PropsWithAs,
} from "theme/utils";

import {
  contentWrapperStyles,
  leftIconWrapperStyles,
  rightIconWrapperStyles,
  statusStyles,
} from "./config";

export type StatusVariant = keyof TTheme["status"]["variants"];
export type StatusSize = keyof TTheme["status"]["sizes"];

export interface StatusComponentProps {
  variant?: StatusVariant;
  size?: StatusSize;
  iconLeft?: React.ReactNode;
  iconRight?: React.ReactNode;
}

const getKey = (
  _theme: TTheme["status"]["sizes"],
  size: StatusSize | TResponsiveValue<StatusSize>
) => {
  return Array.isArray(size) ? size.join("-") : size;
};

const getSize = memoize(getResponsiveStyles, getKey);

const useStatusStyles = (props: StatusComponentProps) => {
  const { variant = "secondary", size = "md" } = props;
  const [colorMode] = useColorMode();

  return useCallback(
    (theme: TTheme) => {
      return {
        ...statusStyles,
        ...getSize(theme.status.sizes, size),
        variant: `status.variants.${variant}.${colorMode}`,
      };
    },
    [variant, size, colorMode]
  );
};

const StatusComponent = (
  props: PropsWithAs<StatusComponentProps>,
  forwardedRef: React.Ref<HTMLSpanElement>
) => {
  const {
    as: Component = "span",
    iconLeft,
    iconRight,
    children,
    ...rest
  } = props;

  const sxFun = useStatusStyles(props);

  return (
    <Component ref={forwardedRef} sx={sxFun} {...rest}>
      <span data-content-wrapper sx={contentWrapperStyles}>
        {iconLeft && (
          <span data-left-icon-wrapper sx={leftIconWrapperStyles}>
            {iconLeft}
          </span>
        )}

        {children && <span data-children>{children}</span>}

        {iconRight && (
          <span data-right-icon-wrapper sx={rightIconWrapperStyles}>
            {iconRight}
          </span>
        )}
      </span>
    </Component>
  );
};

export type StatusProps = PropsWithAs<StatusComponentProps, "span">;
export const Status = forwardRefWithAs<StatusComponentProps, "span">(
  StatusComponent
);
