/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from 'theme-ui';
import React from "react";

interface Props {
  className?: string
}

const style = { p: "sm" };

export const PageContent: React.FC<Props> = ({ children, className }) => {
  return <div sx={style} className={className}>{children}</div>;
};
