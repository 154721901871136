export const boardStyles = {
  flex: 1,
  display: "flex",
  position: "relative",

  borderRadius: "xl",
  p: "sm",

  fontSize: "32px",
  lineHeight: "48px",
  fontWeight: "bold",

  userSelect: "none",

  "[data-pointer]": {
    position: "absolute",
    content: "''",
    top: 0,
    left: 0,
    width: "15px",
    height: "15px",
    borderRadius: "50%",
    backgroundColor: "yellow",
    transition: "transform",
    transform: 'translateX(-99999px)',
  },

  "[data-inner-container]": {
    position: "relative",
    margin: "0 auto",
  },

  "[data-pointer-svg]": {
    position: "absolute",
    top: 0,
    left: 0,

    path: {
      fill: "none",
      // stroke: "red",
      strokeLinecap: "round",
      strokeLinejoin: "round",
    }
  },

  "[data-text]": {
    position: 'relative',
  },

  "[data-word]": {
    wordBreak: "break-word",
    display: "inline-block",
  },

  "&[data-text-position='center']": {
    "[data-inner-container]": {
      display: "flex",
    },
    "[data-chunk]": {
      margin: "auto",
      textAlign: "center",
    }
  },

  "&[data-text-position='random']": {
    overflowX: "hidden",

    "[data-inner-container]": {
      display: "inline",
    },
    "[data-chunk]": {
      display: "inline",
    }
  },

  "&[data-text-position='justified']": {
    textAlign: "justify",
  },

  "&[data-upside-down='true'] [data-word]": {
    transform: "scale(1, -1)",
  },

  "[data-hidden-chars]": {
    color: "transparent",
    borderRadius: "md",
  },

  "@media (min-height:500px)": {
    flex: "unset",
    minHeight: "400px",
  }
}
