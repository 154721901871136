import React from "react";

interface Props {
  children: React.ReactNode;
}

export const BoardInnerContainer = React.forwardRef<HTMLDivElement, Props>(({ children, ...rest }, ref) => (
  <div ref={ref} data-inner-container {...rest}>
    {children}
  </div>
));
