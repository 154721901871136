import { ThemeUICSSObject } from "theme-ui";
import { TModeColor } from "theme";
import { TColor } from "theme/colors";

export const statusStyles: ThemeUICSSObject = {
  display: "inline-flex",
  fontWeight: "bolder",
  borderRadius: "4px",
};

export const contentWrapperStyles: ThemeUICSSObject = {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
};

const iconWrapperStyles: ThemeUICSSObject = {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
};

export const leftIconWrapperStyles: ThemeUICSSObject = {
  ...iconWrapperStyles,
};

export const rightIconWrapperStyles: ThemeUICSSObject = {
  ...iconWrapperStyles,
};

const makeVariant = ({ backgroundColor, color }: Record<string, string>) => ({
  backgroundColor,
  color,
});

type VariantProps = Record<string, TColor | "transparent">

type MakeVariants = Record<TModeColor, VariantProps>

const makeVariants = (props: MakeVariants) => {
  const variants: Record<TModeColor, {}> = {
    default: {},
    dark: {},
  };
  Object.keys(props).forEach(mode => {
    variants[mode as TModeColor] = makeVariant(props[mode as TModeColor]);
  });

  return variants;
};

const variants = {
  secondary: makeVariants({
    default: {
      backgroundColor: "n5",
      color: "n50",
    },
    dark: {
      backgroundColor: "n70",
      color: "n0",
    }
  }),
};

const makeSize = ({
  height,
  fontSize,
  lineHeight,
  padding,
  iconSize,
}: Record<string, string>) => ({
  height,
  fontSize,
  lineHeight,
  padding,

  "[data-icon]": {
    fontSize: iconSize,
  },

  "[data-left-icon-wrapper] + [data-children]": {
    ml: "xxs",
  },

  "[data-left-icon-wrapper] + [data-right-icon-wrapper]": {
    ml: "xxs",
  },

  "[data-children] + [data-right-icon-wrapper]": {
    ml: "xxs",
  },
});

const sizes = {
  sm: makeSize({
    height: "20px",
    fontSize: "12px",
    lineHeight: "16px",
    padding: "0 4px",
    iconSize: "12px",
  }),
  md: makeSize({
    height: "24px",
    fontSize: "14px",
    lineHeight: "20px",
    padding: "2px 8px",
    iconSize: "16px",
  }),
  lg: makeSize({
    height: "32px",
    fontSize: "14px",
    lineHeight: "20px",
    padding: "6px 12px",
    iconSize: "20px",
  }),
};

export const status = {
  variants,
  sizes,
};
