import React, { useCallback, useMemo } from "react";
import {
  ThemeProvider as ThemeProviderBase,
  useThemeUI as useThemeUIBase,
  useColorMode as useColorModeBase,
} from "theme-ui";

import { setItem } from "utils";

import { theme, COLOR_MODE_STORAGE_KEY, TModeColor as TModeColoConfig } from "./config";
import { GlobalStyles } from "./GlobalStyles";

export type TTheme = typeof theme;

export const ThemeProvider: React.FC = ({ children }) => {
  return (
    <React.Fragment>
      <GlobalStyles />
      <ThemeProviderBase theme={theme}>{children}</ThemeProviderBase>
    </React.Fragment>
  );
};

export const useThemeUI = () => {
  const { theme } = useThemeUIBase();

  if (!theme) {
    throw new Error("Missing provider for the theme");
  }

  return theme as TTheme;
};

export type TModeColor = TModeColoConfig;

export const useColorMode = (): [TModeColor, (v: TModeColor) => void] => {
  const [colorMode, setColorMode] = useColorModeBase();

  const handleColorModeChange = useCallback((v: TModeColor) => {
    setItem(COLOR_MODE_STORAGE_KEY, v);
    setColorMode(v);
  }, [setColorMode]);

  return useMemo(() => {
    return [colorMode as TModeColor, handleColorModeChange]
  }, [colorMode, handleColorModeChange]);
};
