import sanitizeHtml from "sanitize-html";

const EDITABLE_CONTENT = {
  allowedTags: ['div', 'p', 'br', 'ul', 'ol', 'li'],
  allowedAttributes: {},
};

export const cleanEditableContent = (html: string) => {
  return sanitizeHtml(html, EDITABLE_CONTENT);
}
